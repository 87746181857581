import React, { useEffect, useReducer, useRef, useState } from "react";
import axios from "axios";
import Header from "../../Header";
import Sidebar from "./Sidebar";
import MockbotExperince from "./pages/MockbotExperince";
import User from "./pages/User";
import Feedback from "./pages/Feedback";
import TrialManagement from "./pages/TrialManagement";
import Subscription from "./pages/Subscription";
import ContactTable from "./tables/ContactTable";
import { fade } from "@material-ui/core";
import AnalysisTable from "./tables/AnalysisTable";
import PromoCode from "./tables/PromoCode";
const Tracking = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [data, setData] = useState();
  const [subjects, setSubjects] = useState();
  const [emailData, setEmailData] = useState();
  const [feedbackData, setFeedbackData] = useState();
  const [currentQuestionFeedbackPage2, setCurrentQuestionFeedbackPage2] =
    useState(1);
  const [numberOfRowsQuestionFeedback2, setNumberOfRowsQuestionFeedback2] =
    useState(150);
  const [numberOfRows, setNumberOfRows] = useState(150);
  const [isClicked, setIsClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEmailPage, setCurrentEmailPage] = useState(1);
  const [numberOfRows2, setNumberOfRows2] = useState(150);
  const [questionFeedbackData, setQuestionFeedbackData] = useState();
  const [currentQuestionFeedbackPage, setCurrentQuestionFeedbackPage] =
    useState(1);
  const [numberOfRowsQuestionFeedback, setNumberOfRowsQuestionFeedback] =
    useState(150);
  const [selectedTab, setSelectedTab] = useState("mockExperience");
  const loading = useRef(false);
  const totalPages = Math.ceil(data?.length / numberOfRows2);
  const totalEmailPages = Math.ceil(emailData?.length / numberOfRows);
  const totalFeedbackPages = Math.ceil(
    questionFeedbackData?.length / numberOfRowsQuestionFeedback
  );
  const totalFeedbackPages2 = Math.ceil(
    feedbackData?.length / numberOfRowsQuestionFeedback2
  );
  // Change page handlers
  const handlePageChange = (newPageNumber) => {
    setCurrentPage(Math.max(1, Math.min(newPageNumber, totalPages)));
  };

  const handleEmailPageChange = (newPageNumber) => {
    setCurrentEmailPage(Math.max(1, Math.min(newPageNumber, totalEmailPages)));
  };
  const handleFeedbackPageChange = (newPageNumber) => {
    setCurrentQuestionFeedbackPage(
      Math.max(1, Math.min(newPageNumber, totalFeedbackPages))
    );
  };
  const handleFeedbackPageChange2 = (newPageNumber) => {
    setCurrentQuestionFeedbackPage2(
      Math.max(1, Math.min(newPageNumber, totalFeedbackPages2))
    );
  };
  const handleSubmit = async (e) => {
    loading.current = true;
    setIsClicked(true);
    e.preventDefault();
    const loginData = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/admin_login`,
        loginData
      );
      console.log("Login successful", response.data);
      if (response.data.state === 0) {
        loading.current = false;
        setIsClicked(false);
        alert("Invalid  credentials");
      } else {
        setIsLoggedIn(true);
        setIsClicked(false);
        var data = response.data;
        const data_new = Array.isArray(response.data.login_user_data)
          ? response.data.login_user_data
          : Object.values(response.data.login_user_data);
        setFeedbackData(data_new);
        setData(data.data);
        setEmailData(data.trial_emails);
        loading.current = false;
      }
    } catch (error) {
      setIsClicked(false);
      alert("login failed");
      loading.current = false;
      console.error("Login failed", error.response || error);
      setEmail("");
      setPassword("");
    }
  };

  const getSubjects = async (e) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects_admin`
      );
      console.log("Subjects Here:", response.data);
      setSubjects(response.data);
    } catch (error) {
      console.error("Subjects failed", error.response || error);
    }
  };

  function aggregateWords(input) {
    if (!input || input.toLowerCase() === "undefined") return "All Topics"; // Handle null, undefined, or "undefined" string

    const words = input.split(",").map((word) => word.trim()); // Split and trim spaces
    const counts = {}; // Object to hold word counts

    // Iterate over words to populate counts object
    words.forEach((word) => {
      // Treat "undefined" as "All Topics"
      const normalizedWord = word === "undefined" ? "All Topics" : word;

      // Increment count for the word, initializing if necessary
      if (counts[normalizedWord]) {
        counts[normalizedWord]++;
      } else {
        counts[normalizedWord] = 1;
      }
    });

    // Construct the result array from the counts object
    const result = Object.entries(counts).map(([word, count]) => {
      return count > 1 ? `${word} (${count})` : word;
    });

    return result.join(", ");
  }

  function handleRowChange(event) {
    setNumberOfRows(Number(event.target.value));
    setCurrentEmailPage(1);
  }
  function handleRowChange1(event) {
    setNumberOfRows2(Number(event.target.value));
    setCurrentPage(1);
  }
  function handleRowChangeFeedback(event) {
    setNumberOfRowsQuestionFeedback(Number(event.target.value));
    setCurrentQuestionFeedbackPage(1);
  }
  function handleRowChangeFeedback2(event) {
    setNumberOfRowsQuestionFeedback2(Number(event.target.value));
    setCurrentQuestionFeedbackPage2(1);
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const getQuestionFeedback = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_question_level_feedbacks`
      );
      console.log("feedback Here:", response.data);
      setQuestionFeedbackData(response.data.data);
    } catch (error) {
      console.error("Subjects failed", error.response || error);
    }
  };

  useEffect(() => {
    getQuestionFeedback();
    getSubjects();
  }, []);
  useEffect(() => {
    console.log("Feedback Data Updated:", feedbackData);
  }, [feedbackData]);
  if (isLoggedIn) {
    const indexOfLastItem = currentPage * numberOfRows2;
    const indexOfFirstItem = indexOfLastItem - numberOfRows2;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    const indexOfLastEmail = currentEmailPage * numberOfRows;
    const indexOfFirstEmail = indexOfLastEmail - numberOfRows;
    const currentEmails = emailData?.slice(indexOfFirstEmail, indexOfLastEmail);

    const indexOfLastFeedback =
      numberOfRowsQuestionFeedback === questionFeedbackData?.length
        ? questionFeedbackData?.length
        : currentQuestionFeedbackPage * numberOfRowsQuestionFeedback;

    const indexOfFirstFeedback =
      numberOfRowsQuestionFeedback === questionFeedbackData?.length
        ? 0
        : indexOfLastFeedback - numberOfRowsQuestionFeedback;

    const questionFeedback = questionFeedbackData?.slice(
      indexOfFirstFeedback,
      indexOfLastFeedback
    );
    const indexOfLastFeedback2 =
      currentQuestionFeedbackPage2 * numberOfRowsQuestionFeedback2;
    const indexOfFirstFeedback2 =
      indexOfLastFeedback2 - numberOfRowsQuestionFeedback2;

    const Feedback_app = feedbackData?.slice(
      indexOfFirstFeedback2,
      indexOfLastFeedback2
    );

    return (
      <>
        <Header />
        <div className="flex">
          <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <div className="flex flex-col  mt-12 w-full px-4">
            {selectedTab === "mockExperience" && (
              <MockbotExperince
                currentItems={currentItems}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
                numberOfRows2={numberOfRows2}
                setNumberOfRows2={setNumberOfRows2}
                handleRowChange1={handleRowChange1}
                data={data}
                aggregateWords={aggregateWords}
                hoveredIndex={hoveredIndex}
                setHoveredIndex={setHoveredIndex}
                currentEmails={currentEmails}
                handleEmailPageChange={handleEmailPageChange}
                currentEmailPage={currentEmailPage}
                totalEmailPages={totalEmailPages}
                numberOfRows={numberOfRows}
                setNumberOfRows={setNumberOfRows}
                handleRowChange={handleRowChange}
                emailData={emailData}
                currentFeedback={questionFeedback} // Pass the current page items
                handleFeedbackPageChange={handleFeedbackPageChange}
                currentFeedbackPage={currentQuestionFeedbackPage}
                totalFeedbackPages={totalFeedbackPages} // Pass total pages for Question Feedback
                numberOfFeedbackRows={numberOfRowsQuestionFeedback}
                handleRowChangeFeedback={handleRowChangeFeedback}
                FeedbackQuestion={questionFeedbackData}
                setSubjects={setSubjects}
                subjects={subjects}
                getSubjects={getSubjects}
              />
            )}
            {selectedTab === "userProfile" && <User />}
            {selectedTab === "feedback" && (
              <Feedback
                Feedback_app={Feedback_app}
                handleFeedbackPageChange2={handleFeedbackPageChange2}
                currentQuestionFeedbackPage2={currentQuestionFeedbackPage2}
                totalFeedbackPages2={totalFeedbackPages2}
                numberOfRowsQuestionFeedback2={numberOfRowsQuestionFeedback2}
                setCurrentQuestionFeedbackPage2={
                  setCurrentQuestionFeedbackPage2
                }
                handleRowChangeFeedback2={handleRowChangeFeedback2}
                hoveredIndex={hoveredIndex}
                feedbackData={feedbackData}
                setHoveredIndex={setHoveredIndex}
                aggregateWords={aggregateWords}
              />
            )}
            {selectedTab === "TrailManagement" && <TrialManagement />}
            {selectedTab === "SubscriptionManagement" && <Subscription />}
            {selectedTab === "contact" && <ContactTable />}
            {selectedTab === "Analysis" && <AnalysisTable />}
            {selectedTab === "Promo" && <PromoCode />}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className=" flex items-center justify-center h-screen">
        <form onSubmit={handleSubmit} className="w-full max-w-xs">
          <h3 className="font-bold text-center mb-[24px]">Admin Login</h3>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              disabled={isClicked}
              className="w-full flex bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              <span className="text-center w-full">Sign In </span>
              {loading.current === true && (
                <svg
                  className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tracking;
