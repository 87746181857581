import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AcknowledgeIcon from "../../../../images/Danger.png";
import { useNavigate } from "react-router-dom";

function SubscriptionPopUp({ isAcknowledgement, setIsAcknowledgement }) {
  const navigate = useNavigate();

  if (!isAcknowledgement) return null;

  const handleClose = () => {
    // navigate("/");
    setIsAcknowledgement(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "438px",
          height: "300px",
          maxWidth: "438px",
          maxHeight: "400px",
          borderRadius: "12px",
          overflow: "hidden",
        },
      }}
      open={isAcknowledgement}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: "absolute", top: "20px", right: "25px" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="text-center ">
        <center>
          {" "}
          <img src={AcknowledgeIcon} alt="Start Trial" />
        </center>

        <h2 className="text-3xl font-bold text-[#1D192B] mt-4">
          Subscription Active
        </h2>
        <p className="text-base font-medium text-[#49454F] mt-[8px]">
          You are currently subscribed, and cannot unenroll. You can cancel or
          manage your subscription by going to Settings {">"} Subscription
          management
        </p>
        <button
          onClick={handleClose}
          className="mt-4 text-lg font-semibold bg-[#444780] text-white rounded-lg px-6 py-3 text-center shadow-lg hover:bg-[#37366c] transition-colors"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#FFFFFF",
          }}
        >
          Okay
        </button>
      </DialogContent>
    </Dialog>
  );
}

export default SubscriptionPopUp;
