import AppContext from "./appContext";
import React, { useState } from "react";
const AppState = (props) => {
  const [secretKey, setSecretKey] = useState("");
 
 
  const [loginState, setLoginState] = useState(2);




  return (
    <AppContext.Provider
      value={{
        secretKey,
        setSecretKey,

       
        loginState,
        setLoginState,

      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
