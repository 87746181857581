import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../images/white-logo.png";
import logoutIcon from "../images/logout.png"; // Ensure the path is correct

const Header = ({ stage }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility
  var email = localStorage.getItem("email_address");
  var name = localStorage.getItem("name");
  const dropdownRef = useRef(); // Ref for the dropdown
  // Use Tailwind's conditional classes for background color
  const backgroundColorClass = stage === "end" ? "bg-gray-100" : "bg-white";

  const handleLogoClick = () => {
    if (window.location.pathname === "/") {
      window.location.reload();
    } else if (window.location.pathname === "/about_you") {
      return;
    } else {
      navigate("/");
    }
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("topic_id");
    localStorage.removeItem("subject_id");
    localStorage.removeItem("product_id");
    localStorage.removeItem("plan");
    localStorage.removeItem("name");
    localStorage.removeItem("days");
    localStorage.removeItem("trail_days");
    localStorage.removeItem("trial_id");
    localStorage.removeItem("user_type");
    localStorage.removeItem("subject_name");
    localStorage.removeItem("topic_name");
    localStorage.removeItem("email_address");
    localStorage.removeItem("trial_days");
    localStorage.removeItem("amount");
    window.location.reload();
    // Add navigation or other actions needed after logout
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropdown]); // Only re-run if showDropdown changes
  const getInitials = (name) => {
    return name ? name[0].toUpperCase() : "";
  };
  return (
    <div
      className={`flex items-center justify-between md:justify-start md:px-10 lg:px-16 xl:px-40 2xl:px-60 ${backgroundColorClass}`}
    >
      <button onClick={handleLogoClick}>
        <img
          src={logo}
          alt="logo"
          className="mt-[8px] md:mt-[16px] w-[201px]"
        />
      </button>
      {/* Spacer to push the logout icon to the right */}
      <div className="flex-grow"></div>

      <div
        className="relative mt-[24px]"
        style={{
          display: window.location.pathname === "/admin_tracking" ? "none" : "",
        }}
      >
        <button onClick={() => setShowDropdown(!showDropdown)}>
          <img src={logoutIcon} alt="Logout" className=" cursor-pointer" />
        </button>
        {showDropdown && (
          <div
            ref={dropdownRef}
            className="absolute right-0  bg-white shadow-xl rounded-lg z-50 flex flex-col items-start"
          >
            <div className="flex items-center p-2  hover:bg-gray-100  border-b border-b-2 border-gray-300">
              <div className="flex items-center justify-center bg-[#444780] rounded-full h-10 w-10 text-white font-bold">
                {getInitials(name)}
              </div>
              <div className="ml-2">
                <div className="text-gray-700 font-bold truncate">
                  {name?.replace("|", " ")}
                </div>
                <div className="text-gray-600 text-sm truncate">{email}</div>
              </div>
            </div>
            <button
              className="w-full px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none text-center border-b border-b-2 border-gray-300"
              onClick={() => {
                navigate("/settings");
              }}
            >
              Settings
            </button>
            <button
              className="w-full px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none text-center border-b border-b-2 border-gray-300"
              onClick={() => {
                navigate("/contact_us");
              }}
            >
              Contact us
            </button>

            <button
              className="w-full px-4 py-2 text-gray-700 hover:bg-gray-100 focus:outline-none text-center"
              onClick={handleLogoutClick}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
