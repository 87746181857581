import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { Link, useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { MuiOtpInput } from "mui-one-time-password-input";
import TextField from "@material-ui/core/TextField";
import google_icon from "../pages/images/ic_google.svg";
import microsoft_icon from "../pages/images/ic_microsoft.svg";
import appContext from "../../context/appContext";
import validator from "validator";
import LoginPageLeftSide from "./LoginPageLeftSide";
import { useMsal } from "@azure/msal-react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
const PurpleCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.getContrastText("#444780"), // Color for unchecked state
  "&.Mui-checked": {
    color: "#444780", // Custom color for checked state
  },
  "&.MuiCheckbox-root": {
    color: "#444780", // Ensures the checkbox has a visible color when unchecked
  },
  // Adjusting the size of the checkbox icon
  "& svg": {
    width: "16px", // Set the width of the checkbox
    height: "16px", // Set the height of the checkbox
  },
}));

function Register() {
  React.useEffect(() => {
    if (window.location.pathname === "/register") {
      document.title = "Sign Up | MockBot";
    } else {
      document.title = "MockBot"; // Optionally reset title when component unmounts
    }
  }, []);
  React.useEffect(() => {
    try {
    } catch (e) {
      console.log(e);
    }
  }, []);
  const [user, setUser] = useState();
  const [update, setUpdate] = useState(true);
  const [otp, setOtp] = React.useState("");
  const { instance } = useMsal();
  const [passwordCriteraMatch, setPasswordCriteraMatch] = React.useState(true);
  const [confirmPasswordCriteraMatch, setConfirmPasswordCriteraMatch] =
    React.useState(true);
  const [passwordHelperText, setPasswordHelperText] = React.useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    React.useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isValidName, setIsValidName] = useState(false);
  const [nameError, setNameError] = useState("");
  const [isValidLastName, setIsValidLastName] = useState(false);
  const [lastNameError, setLastNameError] = useState("");
  const [isPressed, setIsPressed] = useState(false);
  const loading = useRef(false);
  const isEnterOTP = useRef(false);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const [credentials, setCredentials] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    one_time_password: "",
  });

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [signUpStep, setSignUpStep] = useState(0);
  const state = useContext(appContext);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  useEffect(() => {
    if (user != null) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          checkUserAccountAndThenLogin(res.data, 2, res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const loginWithMicrosoft = () => {
    instance
      .loginPopup({
        scopes: ["email"],
      })
      .then((result) => {
        return result;
      })
      .then((result) => {
        checkUserAccountAndThenLogin(result.account, 3);
      })
      .catch((err) => {
        console.log(err.errName, err.errType);
      });
  };

  function checkUserAccountAndThenLogin(email, auth_type, data) {
    // console.log(data);

    const url = "login_with_google_or_ms_verified_email";

    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: auth_type === 3 ? email.username : email.email,

          name: email.name,
        }),
      })
      .then((res) => {
        // console.log(res);
        localStorage.setItem("access_token", res.data.access_token);

        localStorage.setItem(
          "email_address",
          auth_type === 3 ? email.username : email.email
        );

        localStorage.setItem("name", email.name);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const createUserAccount = (email, password, auth_type, name, lastName) => {
    if (isEnterOTP.current === true) {
      return;
    } else {
      // console.log("URL IS: ", process.env);
      loading.current = true;
      setIsPressed(true);
      const url = "/create_new_user_by_otp_authentication_student";
      var fullname = name + " | " + lastName;
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + url,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            name: fullname,
            email: email,
            password: password,
          }),
        })
        .then((res) => {
          // console.log(res);
          localStorage.setItem("name", fullname);
          setSignUpStep(2);
          setIsPressed(false);
          loading.current = false;
          // amplitude.getInstance().setUserId(email);
          // amplitude.getInstance().logEvent("SU-CwithWorkEmail");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 409) {
            setIsPressed(false);
            loading.current = false;
            alert("email already exists");
            return;
          }
        });
    }
  };

  const verifyOtp = (email, one_time_password, e) => {
    if (one_time_password !== "") {
      e.preventDefault();
      const url =
        state.loginState === 1 ? "/verify_otp_student" : "/verify_otp_student";
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + url,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            email_address: email,
            one_time_password: one_time_password,
          }),
        })
        .then((res) => {
          // console.log(res);

          localStorage.setItem("access_token", res.data.access_token);
          if (state.loginState === 1) {
            localStorage.setItem("user_type", "3");
          } else {
            localStorage.setItem("user_type", res.data.user_type);
          }
          localStorage.setItem("email_address", email);
          // console.log("DATA:", res.data);
          if (res.data.msg === "Invalid OPT") {
            alert("You have entered an invalid OTP");
          } else {
            navigate("/about_you");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
          }

          console.log(err);
        });
    } else {
      alert(
        "To proceed, please enter the 5-digit OTP sent to your registered email."
      );
      e.preventDefault();
    }
  };

  const signUpUser = (e) => {
    e.preventDefault();
    if (
      credentials.name.trim() === "" ||
      credentials.lastName.trim() === "" ||
      credentials.email.trim() === "" ||
      credentials.password.trim() === "" ||
      credentials.confirm_password.trim() === ""
    ) {
      alert("Please fill in all fields");
      return;
    }

    if (!/^[A-Za-z ]+$/.test(credentials.name.trim())) {
      setNameError("Name should only contain alphabet ");
      setIsValidName(true);
      isEnterOTP.current = true;
    }

    if (!/^[A-Za-z ]+$/.test(credentials.lastName.trim())) {
      setLastNameError("Last name should only contain alphabet");
      setIsValidLastName(true);
      isEnterOTP.current = true;
    }

    if (validator.isEmail(credentials.email)) {
    } else {
      setEmailError("Enter valid Email!");
      setIsValidEmail(true);
      isEnterOTP.current = true;
    }

    if (!credentials.password.match("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$")) {
      setPasswordHelperText(
        "Password must have atleast 6 characters including alphabets and numbers."
      );
      setPasswordCriteraMatch(false);
      return;
    }
    setPasswordHelperText("");
    setPasswordCriteraMatch(true);

    if (credentials.password !== credentials.confirm_password) {
      setConfirmPasswordHelperText(
        "Confirm password did not match with the password entered"
      );
      setConfirmPasswordCriteraMatch(false);
      return;
    }

    setConfirmPasswordHelperText("");
    setConfirmPasswordCriteraMatch(true);

    createUserAccount(
      credentials.email,
      credentials.password,
      1,
      credentials.name,
      credentials.lastName
    );
  };
  const onChange = (e) => {
    if (e.target.name === "email") {
      setIsValidEmail(false);
      setEmailError("");
    }
    if (e.target.name === "name") {
      setIsValidName(false);
      setNameError("");
    }
    if (e.target.name === "lastName") {
      setIsValidLastName(false);
      setLastNameError("");
    }
    isEnterOTP.current = false;
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          titleStyle={{ textAlign: "center" }}
          textAlign="center"
        >
          <b>Thank you!</b>
        </DialogTitle>
        <DialogContent titleStyle={{ textAlign: "center" }} textAlign="center">
          <DialogContentText
            id="alert-dialog-description"
            titleStyle={{ textAlign: "center" }}
            textAlign="center"
          >
            Someone will get in touch shortly
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyItems: "center", justifyContent: "center" }}
        >
          <button
            fullWidth
            type="submit"
            style={{ width: "180px", height: "45px" }}
            className="early_access__dialog_close_button  hover:text-white font-bold py-2 px-4 "
            onClick={handleClose}
          >
            Close
          </button>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden ">
          <LoginPageLeftSide />
          {/* page 2 */}
          {signUpStep === 0 ? (
            <div className="h-full w-full bg-white centered md:h-screen p-10 md:p-40 sm:p-5">
              <div className="main-content-div w-[500px] sm:w-[360px] md:w-[40px]">
                <div className="sign-in-label ">
                  <p className="text-[24px]">
                    Boost your grades - join MockBot now!
                  </p>
                </div>
                <div className="sign-in-sub-label ">
                  <div className="login-options mb-[16px]">
                    Choose how would you like to create your account
                  </div>
                </div>

                <div>
                  <div className="w-full">
                    <div
                      className="mt-[20px]"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <GoogleButton label="Sign in with Google 🚀 " onClick={() => login()}/> */}
                      <button
                        type="submit"
                        onClick={() => login()}
                        className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                      >
                        <div style={{ display: "inline-block" }}>
                          <div className="flex">
                            <img
                              alt=""
                              className="float-left w-[26px] mr-[10px]"
                              src={google_icon}
                            ></img>
                            Continue with Google
                            <div style={{ visibility: "hidden" }}>ds</div>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      className="mt-[20px]"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        type="submit"
                        onClick={loginWithMicrosoft}
                        className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                      >
                        <div style={{ display: "inline-block" }}>
                          <div className="flex">
                            <img
                              alt=""
                              className="float-left w-[26px] mr-[10px]"
                              src={microsoft_icon}
                            ></img>
                            Continue with Microsoft
                          </div>
                        </div>
                      </button>

                      {/* <MicrosoftLogin
                  buttonTheme="light"
                  className="bg-center"
                  clientId={"82c15ec2-c4b6-47e0-b520-6f3839d026c4"}
                  authCallback={authHandler}
                /> */}
                    </div>
                    <button
                      type="submit"
                      className="mt-[16px] create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                      onClick={() => {
                        setSignUpStep(1);
                        // amplitude.getInstance().setUserId(null);
                        // amplitude.getInstance().logEvent("MAR-Page View");
                      }}
                    >
                      Continue with work email
                    </button>
                  </div>
                  <div className=" float-left  text-[#322E33]  ">
                    {" "}
                    <FormControlLabel
                      control={
                        <PurpleCheckbox
                          checked={update}
                          onChange={() => setUpdate(!update)}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          style={{
                            color: "#322E33",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                          }}
                        >
                          I want to receive updates about Mockbot
                        </Typography>
                      }
                    />
                  </div>
                </div>

                <div className="mt-[0px]">
                  <p
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginBottom: "0px",
                      float: "left",
                      marginRight: "10px",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      className="sign-in-text2 text-[#30335F]"
                      style={{ fontSize: "14px", marginTop: "0px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/login");
                        // amplitude.getInstance().setUserId(null);
                        // amplitude.getInstance().logEvent("SU- ClickSign in");
                      }}
                    >
                      {" "}
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          ) : signUpStep === 1 ? (
            <div className="w-full h-full bg-white centered md:h-screen  ">
              <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
                <div className="sign-in-label  ">
                  <p className="text-[24px]">
                    Boost your grades - join MockBot now!
                  </p>
                </div>

                <div>
                  <div className=" flex justify-center items-center ">
                    <form id="signup-form" className="w-[400px] ">
                      {state.loginState === 2 && (
                        <div>
                          <div className="mt-[2px]">
                            <label className="email-label color-[#49454F]">
                              First Name:
                            </label>
                            <TextField
                              type="text"
                              name="name"
                              style={{
                                height: "45px",
                              }}
                              className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                              id="name"
                              placeholder="Enter your first name"
                              value={credentials.name}
                              onChange={onChange}
                              error={isValidName}
                              helperText={nameError}
                            />
                          </div>
                          <div className="mt-[2px]">
                            <label className="email-label color-[#49454F]">
                              Last Name:
                            </label>
                            <TextField
                              type="text"
                              name="lastName"
                              style={{
                                height: "45px",
                              }}
                              className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                              id="lastName"
                              placeholder="Enter your last name"
                              value={credentials.lastName}
                              onChange={onChange}
                              error={isValidLastName}
                              helperText={lastNameError}
                            />
                          </div>
                        </div>
                      )}

                      <div className="mt-[2px]">
                        <label className="email-label color-[#49454F]">
                          Email:
                        </label>
                        <TextField
                          type="email"
                          name="email"
                          style={{
                            height: "45px",
                            marginBottom: isValidEmail ? "10px" : null,
                          }}
                          className="textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900 "
                          id="email"
                          placeholder="Enter your email"
                          value={credentials.email}
                          onChange={onChange}
                          error={isValidEmail}
                          helperText={emailError}
                        />
                      </div>
                      <div>
                        <label className="email-label mt-[13px] color-[#49454F]">
                          Password:
                        </label>
                        <TextField
                          type="password"
                          name="password"
                          style={{ height: "45px" }}
                          className="myTextField textfield-text  border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                          id="password"
                          error={!passwordCriteraMatch}
                          helperText={passwordHelperText}
                          placeholder="Enter password"
                          value={credentials.password}
                          onChange={onChange}
                        />
                      </div>

                      <div
                        style={{
                          marginTop: passwordHelperText ? "30px" : "0px",
                        }}
                      >
                        <label className="email-label mt-[10px] color-[#49454F] ">
                          Confirm Password:
                        </label>
                        <TextField
                          type="password"
                          name="confirm_password"
                          style={{ height: "45px" }}
                          className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                          id="confirm_password"
                          error={!confirmPasswordCriteraMatch}
                          helperText={confirmPasswordHelperText}
                          placeholder="Enter password again"
                          value={credentials.confirm_password}
                          onChange={onChange}
                        />
                      </div>
                      <div
                        className=" float-left w-full text-[#322E33] w-full "
                        style={{
                          marginTop: !confirmPasswordCriteraMatch
                            ? "15px"
                            : null,
                        }}
                      >
                        {" "}
                        <FormControlLabel
                          control={
                            <PurpleCheckbox
                              checked={update}
                              onChange={() => setUpdate(!update)}
                            />
                          }
                          label={
                            <Typography
                              variant="body1"
                              style={{
                                color: "#322E33",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                              }}
                            >
                              I want to receive updates about Mockbot
                            </Typography>
                          }
                        />
                      </div>
                      <div className="mt-[28px] ">
                        <button
                          type="submit"
                          disabled={isPressed}
                          className="sign-in-button flex  bg-[#444780]    rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                          onClick={(e) => signUpUser(e)}
                        >
                          <span className="text-center w-full">Sign Up </span>
                          {loading.current === true && (
                            <svg
                              className="animate-spin mt-1   ml-2 h-5 w-5 float-right text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                        </button>

                        <div className="mt-[0px]">
                          <p
                            style={{
                              color: "black",
                              fontSize: "14px",
                              marginBottom: "0px",
                              float: "left",
                              marginRight: "10px",
                            }}
                          >
                            Already have an account?
                            <Link
                              className="sign-in-text2"
                              style={{
                                fontSize: "14px",
                                marginTop: "0px",
                                color: "#30335F",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/login");
                                // amplitude.getInstance().setUserId(null);
                                // amplitude
                                //   .getInstance()
                                //   .logEvent("SU- ClickSign in");
                              }}
                            >
                              {" "}
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            signUpStep === 2 && (
              <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-36 sm:p-5">
                <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
                  <div className="sign-in-label ">
                    <p
                      className="segoe-ui text-center"
                      style={{
                        color: "#141414",
                        fontSize: "24px",
                        lineHeight: "32px",
                      }}
                    >
                      OTP Verification
                    </p>
                  </div>
                  <div className="sign-in-sub-label mt-[8px]">
                    <p
                      className="segoe-ui text-center"
                      style={{
                        color: "#49454F",
                        fontSize: "18px",
                        lineHeight: "24px",
                      }}
                    >
                      Please enter the code sent to <br />
                      <b>{credentials.email}</b>
                    </p>
                  </div>
                  <div>
                    <div className="w-full">
                      <form>
                        <div className="mt-[24px]">
                          {/* <input
                    name="one_time_password"
                    style={{ height: "65px" }}
                    className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full py-7 px-4 text-black leading-tight  "
                    id="verification_code"
                    placeholder="Enter your verification"
                    value={credentials.one_time_password}
                    onChange={onChange}
                  /> */}
                          <MuiOtpInput
                            length={5}
                            value={otp}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mt-[24px] ">
                          <button
                            type="submit"
                            className="sign-in-button bg-[#444780]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                            onClick={(e) =>
                              verifyOtp(credentials.email, otp, e)
                            }
                          >
                            Verify
                          </button>

                          <div className="mt-[20px]">
                            <p
                              style={{
                                color: "black",
                                fontSize: "14px",
                                marginBottom: "0px",
                                float: "left",
                                marginRight: "10px",
                              }}
                            >
                              Already have an account?
                              <Link
                                className="sign-in-text2 color-[#30335F]"
                                style={{ fontSize: "14px", marginTop: "0px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/login");
                                }}
                              >
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Register;
