import React, { useState, useEffect, useRef } from "react";
import SubjectSelector from "./component/SubjectSelector";
import TopicSelector from "./component/TopicSelector";
import EmailInput from "./component/EmailInput";
import QuestionDisplay from "./component/QuestionDisplay";
import SessionSummary from "./component/SessionSummary";
import * as animationData from "./images/quiz.json";
import * as animationData2 from "./images/loading2.json";
import * as animationData3 from "./images/noQuestion.json";
import * as animationData4 from "./images/finish.json";
import amplitude from "amplitude-js";
import Lottie from "react-lottie";
import ReactGA from "react-ga4";
import Header from "./component/Header";
import { useNavigate } from "react-router-dom";
import subject_icon from "./images/subject.png";
import topic_icon from "./images/topic.png";
import left_icon from "./images/arrow_left_alt.png";
import close_icon from "./images/close.png";
import moment from "moment";
import FinishQuestionLimit from "./component/pages/User/widgets/FinishQuestionLimit";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import axios from "axios";
import cut from "./images/content_cut.png";
function Question() {
  const navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const [question, setQuestion] = useState([]);
  const [promoCode, setPromoCode] = useState();
  const [show, setShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const prompts = useRef({
    evaluation_identity: "",
    evaluation_example: "",
    evaluation_model: " ",
    evaluation_query: "",
  });
  const [answer, setAnswer] = useState("");
  const [submissionResult, setSubmissionResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [stage, setStage] = useState("showTopics");
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [attempts, setAttempts] = useState([]);
  const [email, setEmail] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const [subject, setSubject] = useState([]);
  const [quizLoading, setQuizLoading] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0); // Add this line
  const [analyzing, setAnalyzing] = useState(false);
  const [questionFinish, setQuestionFinish] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [attemptAdded, setAttemptAdded] = useState(false);
  const [questionSet, setQuestionSet] = useState([]);
  const subjectID = useRef("");
  const [trialData, setTrialData] = useState([]);
  const [isLimit, setIsLimit] = useState(false);

  const [prmpt, setprmpt] = useState();
  const inputToken = useRef("");
  const outputToken = useRef("");
  //Done
  const fetchTopics = async (isInside) => {
    var sbj_id = localStorage.getItem("subject_id");
    const token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_topics_for_student`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject_id: sbj_id,
            current_date: moment().format("Y-MM-DD HH:mm:ss"),
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch topics");

      const data = await response.json();
      setTopics(data.topic_list);
      setTrialData(data.trial_data);
      // console.log("Data" + JSON.stringify(data.trial_data));
      if (stage === "end") {
        return;
      }
      if (!isInside) {
        setStage("showTopics");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  //Done
  const fetchQuestion = async (topicId = null, job = null) => {
    if (trialData[0]?.account_status === 2) {
      setIsLimit(true);
      return;
    }
    if (job === "question") {
      setQuestionNumber((prevNumber) => prevNumber + 1); // Increment question number only on "Next Question"
    } else {
      setQuestionNumber(1);
    }

    const token = localStorage.getItem("access_token");

    try {
      const selectedtopic2 = localStorage.getItem("topic_id");
      const mail = localStorage.getItem("email_address");
      const topic_name = localStorage.getItem("topic_name");
      var sbj_id = localStorage.getItem("subject_id");
      setSelectedTopic(selectedtopic2);
      // console.log("topic pkr " + selectedtopic2);
      // console.log("topic here is " + selectedtopic2);
      // console.log("subject id " + sbj_id);
      let questionIdsCsv = "";

      if (attempts.length > 0) {
        // Create a set from the IDs to remove duplicates, then convert back to array
        const uniqueIds = [...new Set(attempts.map((q) => q.id))];

        // Join the unique IDs into a CSV string
        questionIdsCsv = uniqueIds.join(",");
      } else {
        questionIdsCsv = "0";
      }

      const url = `${process.env.REACT_APP_REST_API_BASE_URL}/fetch_questions_for_student`;
      const body = {
        chapter_id: selectedtopic2 !== "null" ? selectedtopic2 : null,
        subject_id: selectedtopic2 !== "null" ? null : sbj_id,
        trial_subject_id: sbj_id,
        predefined_question_answer_ids_csv: questionIdsCsv,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) throw new Error("Failed to fetch question");

      const text = await response.text();
      const data = text ? JSON.parse(text) : null;

      if (data.question?.length === 0 && questionNumber >= 1) {
        if (data.state === 0) {
          setIsLimit(true);
          questionIdsCsv = "";
          setQuestionNumber((prevNumber) => prevNumber - 1);

          return;
        } else {
          setQuestionFinish(true);
          questionIdsCsv = "";

          return;
        }
      }
      if (data.question && data.question?.length > 0) {
        // console.log("in question" + data.question);
        setQuestion(data.question[0]);
        setAnswer("");

        if (job) {
          setStage("showQuestion");
          if (!topic_name) {
            alert("question number is " + questionNumber);
            if (questionNumber === 0) {
              amplitude.getInstance().logEvent("Selected Topic : -  All Topic");

              ReactGA.event("Select Topic", {
                topic_name: " All Topic", // 'subject_name' as a custom parameter
                // Include any other custom parameters here
              });
            }
          } else {
            if (questionNumber === 0) {
              amplitude
                .getInstance()
                .logEvent("Selected Topic : - " + topic_name);

              ReactGA.event("Select Topic", {
                topic_name: topic_name, // 'subject_name' as a custom parameter
                // Include any other custom parameters here
              });
            }
          }
        } else if (mail) {
          if (topic_name === "undefined") {
            if (questionNumber === 0) {
              amplitude.getInstance().logEvent("Selected Topic : -  All Topic");

              ReactGA.event("Select Topic", {
                topic_name: " All Topic", // 'subject_name' as a custom parameter
                // Include any other custom parameters here
              });
            }
          } else {
            if (questionNumber === 0) {
              amplitude
                .getInstance()
                .logEvent("Selected Topic : - " + topic_name);
              ReactGA.event("Select Topic", {
                topic_name: topic_name, // 'subject_name' as a custom parameter
                // Include any other custom parameters here
              });
            }
          }
          setQuizLoading(true);
          setTimeout(() => {
            setQuizLoading(false);
            setStage("showQuestion");
          }, 5000);
        } else if (!mail) {
          setQuizLoading(true);
          setTimeout(() => {
            setQuizLoading(false);
            setStage("showQuestion");
          }, 5000);
        }
      } else {
        setError("No questions found!");
        setStage("noQuestions");
      }
    } catch (err) {
      setError("No questions found!");
      setStage("noQuestions");
    } finally {
      // Any final cleanup can be done here if needed
    }
  };

  const handleEmailSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const topic_name = localStorage.getItem("topic_name");

    if (emailRegex.test(email)) {
      localStorage.setItem("email_address", email);
      setQuizLoading(true);
      amplitude.getInstance().logEvent("Student Email : " + email);
      if (email) {
        const atIndex = email.indexOf("@"); // Find the index of '@'
        const localPart = email.substring(0, atIndex + 1); // Including '@' in the local part
        const domain = email.substring(atIndex + 1); // The domain part

        ReactGA.event("Enter email", {
          student_email: localPart + " " + domain,
        });
        ReactGA.event("Start Mock button", {
          button: "Start Mock",
        });
      }

      setTimeout(() => {
        setQuizLoading(false);
        setStage("showQuestion");
      }, 5000);
      setEmail("");
    } else {
      alert("Invalid email");
    }
  };

  //Done
  const handleSubmit = async () => {
    const token = localStorage.getItem("access_token");
    setAnalyzing(true);
    var sbjID = localStorage.getItem("subject_id");
    try {
      const quiz_content = {
        question: question.question,
        actual_answer: question.answer,
        rubric: question.rubric,
        answer: answer,
        question_marks: question.question_marks,
      };

      // console.log(
      //   "Indentity:" +
      //     prompts.current.evaluation_identity +
      //     "\n Example : " +
      //     prompts.current.evaluation_example +
      //     "\n question: " +
      //     quiz_content.question +
      //     "\n actual answer" +
      //     quiz_content.actual_answer +
      //     "\n rubric: " +
      //     quiz_content.rubric +
      //     "\n student answer: " +
      //     quiz_content.answer +
      //     "\n Query: " +
      //     prompts.current.evaluation_query
      // );

      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/evaluate_question_for_student`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            question: quiz_content,
            subject_id: sbjID,
            question_marks: question.question_marks,
            question_id: question.predefined_question_answer_id,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to submit answer");

      const data = await response.json();
      setSubmissionResult(data.response.replace(/[#_]/g, " "));
      // console.log("Data is", data);
      if (data.state === 0) {
        setIsLimit(true);
        return;
      }
      const regex = /Marks=(\d+)/;
      const match = data.response.replace(/[#_]/g, " ").match(regex);
      setprmpt(JSON.stringify(data.message));
      inputToken.current = data.input_token;
      outputToken.current = data.output_token;
      setAttempts((prev) => [
        ...prev,
        {
          id: question.predefined_question_answer_id,
          question: question.question,
          answer,
          rubric: question.rubric,
          ai_feedback: data.response.replace(/[#_]/g, " "),
          actual_answer: question.answer,
          question_marks: question.question_marks,
          obtained_marks: parseInt(match[1]),
          question_context: 0,
          image_name: question.image_file_name,
          prompt: JSON.stringify(data.message),
        },
      ]);
      setQuestionSet({
        question: question.question,
        answer,
        rubric: question.rubric,
        ai_feedback: data.response.replace(/[#_]/g, " "),
        actual_answer: question.answer,
        question_marks: question.question_marks,
        obtained_marks: parseInt(match[1]),
        question_context: 0,
        image_name: question.image_file_name,
        prompt: JSON.stringify(data.message),
        input_token: inputToken.current,
        output_token: outputToken.current,
      });

      setTimeout(() => {
        // Stop showing quiz preparation loading once questions are fetched
        // setStage('feedback');
        setAnalyzing(false);
        setAttemptAdded(true);
      }, 4000);
    } catch (error) {
      setError(error.message);
    } finally {
    }
  };

  useEffect(() => {
    if (attemptAdded) {
      // Reset the flag
      setAttemptAdded(false);

      // Now that attempts have been updated, you can safely call submitResponse
      submitResponse();

      // If you need to do something after a certain delay, you can still use setTimeout here
      setTimeout(() => {
        setAnalyzing(false);
        // Any other cleanup or subsequent actions
      }, 4000);
    }
  }, [questionSet, attemptAdded]);

  const handleNextQuestion = () => {
    fetchTopics(true);
    fetchQuestion(selectedTopic, "question");
  };

  const handleSkip = () => {
    amplitude.getInstance().logEvent("Question Skipped");
    fetchQuestion(selectedTopic, "question");
  };
  const submitResponse = async () => {
    var sbj_id = localStorage.getItem("subject_id");
    var sbj_name = localStorage.getItem("subject_name");
    var topic_id = localStorage.getItem("topic_id");
    var topic_name = localStorage.getItem("topic_name");
    var mail = localStorage.getItem("email_address");
    const token = localStorage.getItem("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/submit_response_authentic_student`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            student_email: mail,
            subject_id: sbj_id,
            subject_name: sbj_name,
            topic_id: topic_id,
            topic_name:
              topic_name === "undefined"
                ? sbj_name + " All Topics"
                : topic_name,
            question_list: questionSet,
            response_id: responseId ? responseId : null,
            current_date: moment().format("Y-MM-DD HH:mm:ss"),
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        // console.log("data in response" + JSON.stringify(data.response_id));
        setResponseId(data.response_id);
      } else {
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const handleEnd = async () => {
    setResponseId(null);
    setStage("end");
    setQuestionNumber(1);
    amplitude.getInstance().logEvent("Session Ended");
  };

  const handleBack = () => {
    if (questionFinish) {
      setAttempts([]);
      setQuestionFinish(false);
    }
    if (stage === "end") {
      setAttempts([]);
    }
    setStage("showTopics");
    setError("");
    setAnswer("");
    localStorage.removeItem("topic_id");
  };
  const handleBack2 = () => {
    navigate("/");
    setError("");
    setAnswer("");
  };

  const handleEmail = async () => {
    var mail = localStorage.getItem("email_address");
    const summary = attempts.map((attempt) => attempt.feedback).join("\n");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/send_email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: mail,
            summary: summary,
          }),
        }
      );
      if (response.ok) {
        alert("email sent");
      } else {
        alert("email sending failed");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Done
  const fetchSubject = async () => {
    const url = `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects`;
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch Subject");
      const text = await response.text();
      const data = text ? JSON.parse(text) : null;
      if (data) {
        // console.log("Data" + JSON.stringify(data));
        setSubject(data);
      } else {
        setError("No Subjects found!");
      }
    } catch (err) {
      setError("No Subjects found!");
    } finally {
    }
  };

  useEffect(() => {
    fetchSubject();
  }, []);
  const handleSubject = (id) => {
    subjectID.current = id;
    setLoading(true);
    setLoadingText("“Loading your topics, please wait...”");

    fetchTopics();
    setTimeout(() => {
      setLoading(false);
      setLoadingText("");
    }, 4000);
  };
  const fetchPromoData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_promo_data`
      );
      console.log("data", response.data);
      setPromoCode(response.data.promo_data);
      if (response.data.promo_data.length > 0) {
        setIsLoaded(true);
      } else {
        setIsLoaded(false);
      }
    } catch (error) {
      console.error("Error fetching promo data:", error);
      setIsLoaded(false);
    }
  };
  useEffect(() => {
    fetchPromoData();
    fetchTopics();
  }, []);
  // useEffect(()=> { if(trialData[0]?.remaining_questions === 0){
  //   navigate('/')
  // }},[trialData])
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: animationData3,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: animationData4,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleUpgrade = async (id, name, trial_id) => {
    const token = localStorage.getItem("access_token");
    localStorage.setItem("subject_id", id);
    localStorage.setItem("trial_id", trial_id);
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/submit_buy_interest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          subject_id: id,
          subject_name: name,
        }),
      }
    );
    if (response.ok) {
      window.open("/pricing");

      //  navigate('/pricing');
    } else {
      console.error("Failed");
    }
  };
  const handleCloseClick = () => {
    if (stage === "showTopics") {
      navigate("/");
    } else {
      window.location.reload();
    }
    // Navigate to the home page or any other path as required
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        {" "}
        {/* Full screen centering */}
        <div className="text-center">
          {" "}
          {/* Center text */}
          <div role="status">
            <Lottie options={defaultOptions2} height={176} width={176} />
          </div>
          <br></br>
          <div>
            {loadingText.length > 0
              ? loadingText
              : "“Please wait while we analyze your response...”"}
          </div>
        </div>
      </div>
    );
  }
  if (quizLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div role="status">
            <Lottie options={defaultOptions} height={256} width={256} />
          </div>
          <br />
          <div>Please wait while your quiz is being prepared...</div>
        </div>
      </div>
    );
  }
  if (error === "No questions found!") {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div role="status">
            <Lottie options={defaultOptions3} height={256} width={256} />
          </div>
          <br />
          <div
            className="text-[#322E33] font-semibold mb-[8px]"
            style={{
              fontSize: "22px",
              fontFamily: "Roboto",
              lineHeight: "28px",
            }}
          >
            Oops, you caught me still prepping this one! 
          </div>
          <div
            className="text-[#322E33] font-regular"
            style={{
              fontSize: "16px",
              fontFamily: "Roboto",
              lineHeight: "24px",
            }}
          >
            I'm piecing together some challenging questions. In the meantime,
            why not <br></br> practice other topics?
          </div>
          <div className="flex justify-center w-full mt-[16px]">
            <button
              className="px-6 py-2 mr-3 w-[176px] text-sm text-[#444780] border border-[#444780] background-transparent rounded hover:bg-gray-200 focus:outline-none"
              onClick={() => navigate("/")}
            >
              Go back Home
            </button>
            <button
              className="bg-[#444780] w-[176px] hover:bg-[#444780] hover:opacity-[0.7]  text-white  py-2 px-6 rounded transition duration-150 ease-in-out flex items-center justify-center"
              onClick={() => {
                handleBack();
                setQuestionNumber(0);
              }}
            >
              View Other Topics
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (questionFinish) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div role="status">
            <Lottie options={defaultOptions4} height={256} width={256} />
          </div>
          <br />
          <div
            className="text-[#322E33] font-semibold mb-[16px] mt-[36]"
            style={{
              fontSize: "22px",
              fontFamily: "Roboto",
              lineHeight: "28px",
            }}
          >
            No More Questions in the Hat! 
          </div>
          <div
            className="text-[#322E33] font-regular"
            style={{
              fontSize: "16px",
              fontFamily: "Roboto",
              lineHeight: "24px",
            }}
          >
            Give me a bit to cook up some more challenges. Meanwhile, why not
            recap <br></br> how you've done or pick a new topic
          </div>
          <div className="flex justify-center w-full mt-[16px]">
            <button
              className="px-6 py-2 mr-3 text-sm text-[#444780] border border-[#444780] background-transparent rounded hover:bg-gray-200 focus:outline-none"
              onClick={() => {
                handleBack();
                setQuestionNumber(0);
              }}
            >
              Back to Topic Hub
            </button>
            <button
              className="bg-[#444780] hover:bg-[#444780] hover:opacity-[0.7] w-[178px]  text-white  py-2 px-6 rounded transition duration-150 ease-in-out flex items-center justify-center"
              onClick={() => {
                handleEnd();
                setQuestionFinish(false);
              }}
            >
              Today's Recap
            </button>
          </div>
          <button
            className="px-6 py-2 mr-3 text-sm text-[#444780]  background-transparent rounded hover:bg-gray-200 focus:outline-none mt-[16px]"
            onClick={() => navigate("/")}
          >
            Back to Home
          </button>
        </div>
      </div>
    );
  }
  if (
    error === "No topics found!" &&
    stage !== "noQuestions" &&
    stage !== "feedback"
  )
    return (
      <div className="flex flex-col justify-center items-center min-h-screen gap-4">
        <div className="text-red-500 text-center py-4">{error}</div>{" "}
        <button
          className=" py-[10px] px-[24px]"
          style={{
            border: "1.5px solid #535255",
            color: "white",
            borderRadius: "4px",
            gap: "8px",
            backgroundColor: "black",
          }}
          onClick={handleBack2}
        >
          Back
        </button>
      </div>
    );
  var subjectName = localStorage.getItem("subject_name");
  var topicName = localStorage.getItem("topic_name");
  var subjID = localStorage.getItem("subject_id");
  const handleCopyToClipboard = () => {
    if (promoCode?.length > 0) {
      navigator.clipboard.writeText(promoCode[0]?.promo_code);
      setShow(true);
    }
  };
  return (
    <div className=" ">
      <Header stage={stage} />
      {(trialData[0]?.account_status === 1 ||
        trialData[0]?.account_status === 2) && (
        <div
          className="w-full flex   pb-[8px]  text-white"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            backgroundColor:
              trialData[0]?.remaining_questions === 0 ||
              trialData[0]?.days_left === 0
                ? "#FC586C"
                : "#019BFD",
          }}
        >
          <p className="text-center w-full mt-2">
            {trialData[0]?.days_left === 0
              ? "Your Learning Quest: Your Trial Period has Expired!"
              : trialData[0]?.remaining_questions === 0
              ? "Your Learning Quest: All Free Questions Utilized!"
              : `Maximize Your Learning: Just ${
                  trialData[0]?.remaining_questions
                } ${
                  trialData[0]?.remaining_questions === 1
                    ? "question"
                    : "questions"
                } left, with ${trialData[0]?.days_left} ${
                  trialData[0]?.days_left === 1 ? "day" : "days"
                } to go in your trial!`}{" "}
            <button
              onClick={() => {
                handleUpgrade(
                  subjID,
                  subjectName,
                  trialData[0]?.trial_period_id
                );
              }}
              className="pb-[8px] pt-[8px] pl-[16px] pr-[16px]  ml-2  rounded-lg border-2 border-[white] text-white"
            >
              ⚡ Upgrade
            </button>
          </p>
        </div>
      )}
      <ToastContainer className="fixed top-20 right-4 w-[340px]">
        <Toast
          className="bg-white border border-gray-300 rounded-md shadow-md"
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header
            closeButton={false}
            className="flex items-center justify-between px-2 py-2 border-b border-gray-300"
          >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className=" font-bold  mr-auto ">Copied!</strong>
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </Toast.Header>
          <Toast.Body className="px-2 py-2  bg-[#d8b8ff] opacity-65 ">
            <span className="mr-2"> </span> Promo code has been copied.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {isLoaded && (
        <div
          className="w-full flex   pb-[8px]  text-dark"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            backgroundColor: "#FFD015",
            display: "none",
          }}
        >
          <p className="text-center w-full mt-2  font-[600]">
            {promoCode?.length > 0 ? promoCode[0]?.promo_msg : ""}
            <span className="mr-2 ml-2 text-[20px]">→</span>
            <button
              onClick={handleCopyToClipboard}
              className="pb-[8px]  pt-[8px] pl-[16px] pr-[16px]  ml-2  rounded-lg  text-white bg-black"
              style={{ fontFamily: "Roboto" }}
            >
              <span className="flex gap-1">
                {" "}
                <img
                  className="h-[16px] w-[16px] mt-[2px]"
                  src={cut}
                  alt=""
                />{" "}
                {promoCode?.length > 0 ? promoCode[0]?.promo_code : ""}
              </span>
            </button>
          </p>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: stage === "end" ? "#F2F2F2" : "",
        }}
      >
        <button
          onClick={handleCloseClick}
          style={{
            marginRight: "64px",
            marginTop: "4px",
            cursor: "pointer",
            border: "none",
            background: "none",
            borderRadius: "50%", // Make the button round
            padding: "10px", // Add some padding to create space around the icon
            display: "flex", // Use flex to center the icon inside the button
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.2s", // Smooth transition for the hover effect
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#00000040")
          } // Change background color on hover
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "")} // Revert background color on mouse leave
        >
          <img
            src={close_icon}
            alt="Close"
            style={{ height: "20px", width: "20px" }}
          />
        </button>
      </div>

      {stage === "showQuestion" && (
        <div className="flex gap-2 md:ml-[68px] sm:ml-[4px] mt-1">
          <img className="h-[24px] w-[24px]" src={subject_icon} alt="" />
          <span
            className="text-[#322E33] opacity-80"
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            {subjectName}
          </span>
        </div>
      )}
      {stage === "showQuestion" && (
        <div className="flex gap-2 md:ml-[68px] sm:ml-[4px]] mt-2">
          <img className="h-[24px] w-[24px]" src={topic_icon} alt="" />
          <span
            className="text-[#322E33] opacity-80"
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            <b>Topic:</b> {topicName === "undefined" ? "All Topics" : topicName}
          </span>
        </div>
      )}
      {stage === "end" && (
        <p className="bg-[#F2F2F2] ">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="md:ml-[68px] cursor-pointer flex sm:ml-[4px]  gap-2 font-semibold text-[#444780]"
            style={{
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            <img src={left_icon} alt="" /> Back to Home
          </span>
        </p>
      )}
      {stage === "initial" && (
        <SubjectSelector subjects={subject} onSelectSubject={handleSubject} />
      )}
      {stage === "showTopics" && (
        <TopicSelector
          setSelectedTopic={setSelectedTopic}
          onRestart={handleBack2}
          topics={topics}
          fetchQuestion={fetchQuestion}
        />
      )}
      {stage === "email" && (
        <EmailInput
          email={email}
          setEmail={setEmail}
          onSubmit={handleEmailSubmit}
          stage={stage}
        />
      )}
      {stage === "showQuestion" && question && (
        <QuestionDisplay
          questionNumber={questionNumber}
          feedback={submissionResult}
          question={question}
          answer={answer}
          setAnswer={setAnswer}
          onSubmit={handleSubmit}
          onSkip={handleSkip}
          onEnd={handleEnd}
          modelAnswer={question.answer}
          onNext={handleNextQuestion}
          analyzing={analyzing}
          prompts={prmpt}
        />
      )}

      {stage === "end" && (
        <SessionSummary
          attempts={attempts}
          onRestart={handleBack}
          onEmail={handleEmail}
          fetchTopics={fetchTopics}
        />
      )}
      {stage === "noQuestions" && (
        <div className="flex flex-col justify-center items-center min-h-screen gap-4">
          <p style={{ color: "red" }}> No questions found!</p>
          <button
            className=" py-[10px] px-[24px]"
            style={{
              border: "1.5px solid #535255",
              color: "white",
              borderRadius: "4px",
              gap: "8px",
              backgroundColor: "black",
            }}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      )}
      <FinishQuestionLimit
        isLimit={isLimit}
        setIsLimit={setIsLimit}
        setStage={setStage}
        stage={stage}
        fetchTopics={fetchTopics}
        trialData_1={trialData[0]?.remaining_questions}
        trialData_2={trialData[0]?.days_left}
      />
    </div>
  );
}

export default Question;
