import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Toggler from "../widgets/Toggler";
import LeadingTable from "../tables/LeadingTable";
import OnPressEndTable from "../tables/OnPressEndTable";
import QuestionFeedbackTable from "../tables/QuestionFeedbackTable";
import axios from "axios";
const MockbotExperince = ({
  currentItems,
  handlePageChange,
  currentPage,
  totalPages,
  numberOfRows2,
  setNumberOfRows2,
  handleRowChange1,
  data,
  aggregateWords,
  hoveredIndex,
  setHoveredIndex,
  currentEmails,
  handleEmailPageChange,
  currentEmailPage,
  totalEmailPages,
  numberOfRows,
  setNumberOfRows,
  handleRowChange,
  emailData,
  currentFeedback, // Pass the current page items
  handleFeedbackPageChange,
  currentFeedbackPage,
  totalFeedbackPages, // Pass total pages for Question Feedback
  numberOfFeedbackRows,
  handleRowChangeFeedback,
  subjects,
  setSubjects,
  getSubjects,
  FeedbackQuestion,
}) => {
  const toggleStatus = async (status, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_availability`,
        {
          availability_status: status === 1 ? 0 : 1,
          mock_subjects_id: id,
        }
      );
      console.log("status successful", response.data);
      getSubjects();
    } catch (error) {
      console.error(
        "status failed",
        error.response ? error.response.data : error
      );
    }
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(subjects);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSubjects(items);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subjects_order`,
        {
          list_of_subjects: items, // Ensure this matches the expected format of your API
        }
      );
      console.log("D&D successful", response.data);
      getSubjects();
    } catch (error) {
      console.error(
        "Update failed",
        error.response ? error.response.data : error
      );
    }
  };
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>On press end Table</Tab>
          <Tab>Leading Table</Tab>
          <Tab>Question Feedback Table</Tab>
        </TabList>
        <TabPanel>
          <OnPressEndTable
            currentItems={currentItems}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
            numberOfRows2={numberOfRows2}
            setNumberOfRows2={setNumberOfRows2}
            handleRowChange1={handleRowChange1}
            data={data}
            aggregateWords={aggregateWords}
            hoveredIndex={hoveredIndex}
            setHoveredIndex={setHoveredIndex}
          />
        </TabPanel>

        <TabPanel>
          <LeadingTable
            currentEmails={currentEmails}
            handleEmailPageChange={handleEmailPageChange}
            currentEmailPage={currentEmailPage}
            totalEmailPages={totalEmailPages}
            numberOfRows={numberOfRows}
            setNumberOfRows={setNumberOfRows}
            handleRowChange={handleRowChange}
            emailData={emailData}
          />
        </TabPanel>
        <TabPanel>
          {" "}
          <QuestionFeedbackTable
            currentFeedback={currentFeedback} // Pass the current page items
            handleFeedbackPageChange={handleFeedbackPageChange}
            currentFeedbackPage={currentFeedbackPage}
            totalFeedbackPages={totalFeedbackPages} // Pass total pages for Question Feedback
            numberOfFeedbackRows={numberOfFeedbackRows}
            handleRowChangeFeedback={handleRowChangeFeedback}
            FeedbackQuestion={FeedbackQuestion}
            // ... other props as needed
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MockbotExperince;
