import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import image1 from "../../../../images/CS.png";
import image2 from "../../../../images/GEO.png";
import image3 from "../../../../images/PSY.png";
import ComingSoonModal from "../../../widgets/ComingSoonModal";
import cancl from "../../../../images/cancel.png";
import SubscriptionPopUp from "../widgets/SubscriptionPopUp";
import moment from "moment";
const SubjectsSelection = ({
  country,
  year,
  checked,
  profileExists,
  selectedSubjects,
  copiedSubjects,
  setCopiedSubjects,
}) => {
  const navigate = useNavigate();

  const [subjects, setSubjects] = useState([]);
  const [comingSoon, setCommingSoon] = useState(false);
  const [isAcknowledgement, setIsAcknowledgement] = useState(false);
  const images = [image1, image2, image3]; // Array of images
  const [clicked, setClicked] = useState();
  const [isLoading, setIsLoading] = useState(
    Array(subjects.length).fill(false)
  );

  const getImageIndex = (index) => {
    const pattern = [0, 1, 2, 1, 2, 0];
    return pattern[index % pattern.length];
  };

  const handleSelectSubject = async (selectedSubject, index) => {
    if (selectedSubject.availability_status === 0) {
      setCommingSoon(true);
      return;
    }

    const token = localStorage.getItem("access_token");
    const mail = localStorage.getItem("email_address");

    // Check if the subject is already selected
    const isSubjectSelected = copiedSubjects.some(
      (subject) => subject.subject_id === selectedSubject.subject_id
    );

    // If the subject is not selected, add it
    if (!isSubjectSelected) {
      setCopiedSubjects((prevSelectedSubjects) => [
        ...prevSelectedSubjects,
        selectedSubject,
      ]);
      return;
    }

    // If the subject is selected, call API to check subscription status before removing
    try {
      setIsLoading((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = true;
        return updatedLoading;
      });
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/check_subscription_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            current_date: moment().format("Y-MM-DD HH:mm:ss"),
            subject_id: selectedSubject.subject_id,
            email: mail,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to check subscription status");
        setIsLoading(false);
      }

      const data = await response.json();
      if (data === 1) {
        setIsAcknowledgement(true);
        setIsLoading((prevLoading) => {
          const updatedLoading = [...prevLoading];
          updatedLoading[index] = false;
          return updatedLoading;
        });
        return;
      } else {
        setIsAcknowledgement(false);
        setIsLoading((prevLoading) => {
          const updatedLoading = [...prevLoading];
          updatedLoading[index] = false;
          return updatedLoading;
        });
      }

      // If subscription status allows, remove the subject
      setCopiedSubjects((prevSelectedSubjects) =>
        prevSelectedSubjects.filter(
          (subject) => subject.subject_id !== selectedSubject.subject_id
        )
      );
    } catch (error) {
      console.error("Failed to remove subject:", error);
      // Handle error accordingly
    }
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleSubmit = async () => {
    setClicked(true);
    var time = getCurrentDateTime();
    const token = localStorage.getItem("access_token");
    const subjectIds = copiedSubjects.map((subject) => subject.subject_id);
    const subjectIdsCsv = subjectIds.join(",");

    try {
      const apiUrl = profileExists
        ? `${process.env.REACT_APP_REST_API_BASE_URL}/update_profile_data` // Adjust your "update" endpoint
        : `${process.env.REACT_APP_REST_API_BASE_URL}/add_profile_data`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          country,
          year,
          subject_ids_csv: subjectIdsCsv,
          current_time: time,
          preference: checked ? 1 : 0,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        setClicked(false);
      } else {
        navigate("/");
        setClicked(false);
      }

      // Handle successful submission (navigate or show message)
    } catch (error) {
      console.error("Failed to submit profile data:", error);
      // Handle error (show error message)
    }
  };

  const fetchSubjects = async () => {
    // Assuming this is where your URL will come from
    const url = `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch subjects");
      const data = await response.json(); // Assuming the API returns JSON directly
      setSubjects(data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
      // alert("Failed to fetch subjects.");
    }
  };

  useEffect(() => {
    setCopiedSubjects([...selectedSubjects]);
  }, [selectedSubjects]);

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    setCopiedSubjects([...selectedSubjects]);
  }, [selectedSubjects]);

  return (
    <div className="bg-white shadow-md rounded-lg p-8 mb-4 relative pb-20">
      {" "}
      {/* Added pb-20 for bottom padding */}
      <h2
        className="text-center text-2xl font-semibold my-4"
        style={{
          fontSize: "28px",
          lineHeight: "36px",
          fontFamily: "Roboto",
          color: "#322E33",
        }}
      >
        Select your Subject
      </h2>
      <p
        className="text-center text-gray-600 mb-2"
        style={{
          fontSize: "20px",
          lineHeight: "24px",
          fontFamily: "Roboto",
          color: "#464646",
        }}
      >
        You can select more than one subject from the list.
      </p>
      <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4 mt-[20px] md:mb-[50px] sm:mb-[120px] justify-items-center">
        {subjects.map((subject, index) => (
          <div
            key={subject.subject_id}
            className={`relative rounded-lg overflow-hidden flex justify-center items-center cursor-pointer
                    ${
                      copiedSubjects.find(
                        (s) => s.subject_id === subject.subject_id
                      )
                        ? "border-[3px] border-[#03FFFF]"
                        : ""
                    }`}
            onClick={() => handleSelectSubject(subject)}
            style={{
              backgroundImage: `url(${images[getImageIndex(index)]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "236px", // Set fixed height
              width: "420px", // Set fixed width
            }}
          >
            {copiedSubjects.find(
              (s) => s.subject_id === subject.subject_id
            ) && (
              <div
                className="z-9"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // Light black overlay
                }}
              ></div>
            )}
            <div className="z-8 mb-[160px] text-center font-bold text-2xl text-[#F2F2F2]">
              {subject.subject_name}
            </div>
          </div>
        ))}
      </div>
      {/* Layer for Selected Subjects */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4">
        <hr className=" mb-[20px] w-full border-t border-gray-400" />
        <div className="flex flex-wrap gap-2 justify-center">
          <p
            className="mt-1"
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Roboto",
            }}
          >
            Your Subjects:
          </p>
          {copiedSubjects.map((subject, index) => (
            <div
              key={subject.subject_id}
              className="bg-[#E9E9E9] rounded-full px-4 py-1 flex items-center"
            >
              <span className="text-[#464646]font-semibold">
                {subject.subject_name}
              </span>
              <button
                className="text-[#464646] flex  hover:text-blue-800 ml-2"
                onClick={() => handleSelectSubject(subject, index)}
              >
                <img
                  src={cancl}
                  alt="cancel icon"
                  className="w-[16px] h-[16px]"
                />
                {isLoading[index] && (
                  <svg
                    className="animate-spin inline -mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="#464646"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSubmit}
            disabled={copiedSubjects?.length < 1 || clicked === true}
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontFamily: "Roboto",
              backgroundColor:
                copiedSubjects?.length < 1 ? "#D3D3D3" : "#444780", // Grey background if disabled, else original color
              color: copiedSubjects?.length < 1 ? "#A9A9A9" : "white", // Grey text if disabled, else white
              cursor: copiedSubjects?.length < 1 ? "not-allowed" : "pointer", // Cursor change if disabled
            }}
            className="py-2 px-4 rounded hover:bg-purple-700 transition duration-300 ease-in-out"
          >
            Save and close {">"}
          </button>
        </div>
      </div>
      <ComingSoonModal
        isOpen={comingSoon}
        onClose={() => {
          setCommingSoon(false);
        }}
      />
      <SubscriptionPopUp
        isAcknowledgement={isAcknowledgement}
        setIsAcknowledgement={setIsAcknowledgement}
      />
    </div>
  );
};

export default SubjectsSelection;
