import React, { useRef, useState } from "react";
import Header from "../../../Header";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Button from "@mui/material/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
function ContactUs() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const isSubmitting = useRef(false);
  const [feedbackText, setFeedbackText] = useState("");

  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    isSubmitting.current = true;
    var userFeedback = feedbackText;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/add_feedback_via_contact_us",
        {
          method: "POST",
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          feedback_text: userFeedback,
        }
      )
      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        setShow(true);
        setFeedbackText("");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleDeleteOptionClick = () => {
    if (feedbackText.length <= 0) {
      return;
    }
    setOpen(true);
  };

  const onUserFeedbackChange = (e) => {
    setFeedbackText(e.target.value);
  };

  return (
    <>
      <Header />
      <ToastContainer className="fixed top-20 right-4 w-[340px]">
        <Toast
          className="bg-white border border-gray-300 rounded-md shadow-md"
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header
            closeButton={false}
            className="flex items-center justify-between px-2 py-2 border-b border-gray-300"
          >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className=" font-bold  mr-auto ">Thank you</strong>
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </Toast.Header>
          <Toast.Body className="px-2 py-2  bg-[#d8b8ff] opacity-65 ">
            <span className="mr-2"> </span> We will be in touch shortly.
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit your Message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel} style={{ color: "#444780" }}>
            NO
          </Button>
          <Button
            className="delete-button "
            style={{ color: "#444780" }}
            onClick={handleCloseWithYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="py-[30px] md:pl-[220px] sm:pl-[36px] bg-[#444780] text-white w-full font-extrabold"
        style={{ fontFamily: "Roboto", fontSize: "36px", lineHeight: "44px" }}
      >
        <p
          style={{
            fontFamily: "Roboto",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#D9DBFF",
            width: "contain",
            // Change cursor to pointer to indicate it's clickable
          }}
          // Navigate to "/"
        >
          <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            {" "}
            Home
          </span>
        </p>{" "}
        Contact us{" "}
      </div>
      <div className="flex justify-center items-center mt-4">
        <div className="md:w-[50%] sm:w-[80%]">
          <div>
            <label
              className="text-md font-semibold"
              style={{ fontFamily: "Roboto" }}
            >
              Drop Us a line <span className="text-red-500">*</span>
            </label>
          </div>
          <div className="mt-2">
            <textarea
              type="text"
              name="user_feedback"
              maxLength={500}
              minLength={100}
              required={true}
              id="user_feedback"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-black-500 resize-none"
              placeholder="Send us your message"
              value={feedbackText}
              onChange={onUserFeedbackChange}
              rows={6}
            />
          </div>

          <div className="mt-4 text-center">
            <button
              style={{ fontFamily: "Roboto" }}
              onClick={handleDeleteOptionClick}
              disabled={isSubmitting.current || feedbackText.length === 0}
              className={` px-16 py-2 rounded ${
                feedbackText.length === 0
                  ? "bg-[#1C1B1F] opacity-20 text-[grey] cursor-not-allowed"
                  : "bg-[#444780] hover:opacity-75"
              } text-white border md:float-right `}
            >
              {isSubmitting.current ? (
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <div
            className="md:mt-[84px] sm:mt-4 text-gray-500 text-center"
            style={{ fontFamily: "Roboto" }}
          >
            Or you can email us at{" "}
            <a
              href="mailto:mockbot@highersummit.com"
              className="text-blue-500 underline"
            >
              mockbot@highersummit.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
