import React, { useEffect, useMemo, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import moment from "moment";
const Subscription = () => {
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [totalData, setTotalData] = useState([]);

  const [currentUsageData, setCurrentUsageData] = useState([]);
  const [usageCurrentPage, setUsageCurrentPage] = useState(1);
  const [usageRowsPerPage, setUsageRowsPerPage] = useState(150);
  const [usageData, setUsageData] = useState([]);
  const [currentUsageData2, setCurrentUsageData2] = useState([]);
  const [usageCurrentPage2, setUsageCurrentPage2] = useState(1);
  const [usageRowsPerPage2, setUsageRowsPerPage2] = useState(150);
  const [usageData2, setUsageData2] = useState([]);

  const [managementData, setManagementData] = useState([]);
  const [usageSortedData, setUsageSortedData] = useState([]);
  const [usageSortedData2, setUsageSortedData2] = useState([]);
  const totalPages = Math.ceil(totalData.length / rowsPerPage);
  const usageTotalPages = Math.ceil(usageData.length / usageRowsPerPage);
  const usageTotalPages2 = Math.ceil(usageData2.length / usageRowsPerPage2);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [sortConfig2, setSortConfig2] = useState({
    key: "",
    direction: "ascending",
  });
  const [sortConfig3, setSortConfig3] = useState({
    key: "",
    direction: "ascending",
  });
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const startIndex = (newPage - 1) * rowsPerPage;
    setCurrentData(totalData.slice(startIndex, startIndex + rowsPerPage));
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setCurrentData(totalData.slice(0, newRowsPerPage));
  };

  const handleUsagePageChange = (newPage) => {
    setUsageCurrentPage(newPage);
    const startIndex = (newPage - 1) * usageRowsPerPage;
    setCurrentUsageData(
      usageData.slice(startIndex, startIndex + usageRowsPerPage)
    );
  };

  const handleUsageRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setUsageRowsPerPage(newRowsPerPage);
    setUsageCurrentPage(1);
    setCurrentUsageData(usageData.slice(0, newRowsPerPage));
  };
  const handleUsagePageChange2 = (newPage) => {
    setUsageCurrentPage2(newPage);
    const startIndex = (newPage - 1) * usageRowsPerPage;
    setCurrentUsageData2(
      usageData2.slice(startIndex, startIndex + usageRowsPerPage)
    );
  };

  const handleUsageRowsPerPageChange2 = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setUsageRowsPerPage2(newRowsPerPage);
    setUsageCurrentPage2(1);
    setCurrentUsageData2(usageData2.slice(0, newRowsPerPage));
  };

  const fetchData = async () => {
    try {
      // Ensure the URL is correctly formed
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_all_subscription`,
        {
          headers: { Accept: "application/json" }, // More appropriate header for GET requests
        }
      );

      console.log(
        "Data received2: " + JSON.stringify(response.data.subscription_usage)
      );

      // Check for the presence of data in the response
      if (response.data && response.data.data) {
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : Object.values(response.data.data);
        setTotalData(data);
        setCurrentData(data.slice(0, rowsPerPage));
        const data2 = Array.isArray(response.data.subscription_usage)
          ? response.data.subscription_usage
          : Object.values(response.data.subscription_usage);
        setUsageData2(data2);
        setCurrentUsageData2(data2.slice(0, rowsPerPage));
      } else {
        console.error("No data returned from the API");
        setTotalData([]);
        setCurrentData([]);
        setUsageData2([]);
        setCurrentUsageData2([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setTotalData([]); // Ensure state is clean on error
      setCurrentData([]);
      setUsageData2([]);
      setCurrentUsageData2([]);
    }
  };

  const fetchInterest = async () => {
    try {
      // Ensure the URL is correctly formed
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_buy_interest`,
        {
          headers: { Accept: "application/json" }, // More appropriate header for GET requests
        }
      );

      console.log("Data received: " + JSON.stringify(response.data));

      // Check for the presence of data in the response
      if (response.data && response.data.data) {
        const data = Array.isArray(response.data.data)
          ? response.data.data
          : Object.values(response.data.data);
        setUsageData(data);
        setCurrentUsageData(data.slice(0, rowsPerPage));
      } else {
        console.error("No data returned from the API");
        setTotalData([]);
        setCurrentData([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setTotalData([]); // Ensure state is clean on error
      setCurrentData([]);
    }
  };
  useEffect(() => {
    fetchInterest();
    fetchData();
  }, []);

  function getStatusLabel(status) {
    switch (status) {
      case 1:
        return <span className="bg-[orange] text-white">In Progress</span>;
      case 2:
      case 6:
        return <span className="bg-[red] text-white">Expired</span>;
      default:
        return <span className="status-na">N/A</span>;
    }
  }

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleRowClick = (days) => {
    setPopupData(days);
    setShowPopup(true);
  };
  // In your render or JSX code
  const sortArrayTMT = (colname) => {
    const isAscending =
      sortConfig.key === colname && sortConfig.direction === "ascending";

    const newDirection = isAscending ? "descending" : "ascending";

    setSortConfig({ key: colname, direction: newDirection });

    const sortedArray = [...managementData].sort((a, b) => {
      const dateA = new Date(a[colname]);
      const dateB = new Date(b[colname]);

      if (newDirection === "ascending") {
        return dateA - dateB; // For ascending sort
      } else {
        return dateB - dateA; // For descending sort
      }
    });

    setManagementData(sortedArray);
  };

  const sortArrayUT = (colname) => {
    const isAscending =
      sortConfig2.key === colname && sortConfig2.direction === "ascending";

    const newDirection = isAscending ? "descending" : "ascending";

    setSortConfig2({ key: colname, direction: newDirection });

    const sortedArray = [...usageSortedData].sort((a, b) => {
      const dateA = new Date(a[colname]);
      const dateB = new Date(b[colname]);

      if (newDirection === "ascending") {
        return dateA - dateB; // For ascending sort
      } else {
        return dateB - dateA; // For descending sort
      }
    });

    setUsageSortedData(sortedArray);
  };
  const sortArrayUT2 = (colname) => {
    const isAscending =
      sortConfig3.key === colname && sortConfig3.direction === "ascending";

    const newDirection = isAscending ? "descending" : "ascending";

    setSortConfig3({ key: colname, direction: newDirection });

    const sortedArray = [...usageSortedData2].sort((a, b) => {
      const dateA = new Date(a[colname]);
      const dateB = new Date(b[colname]);

      if (newDirection === "ascending") {
        return dateA - dateB; // For ascending sort
      } else {
        return dateB - dateA; // For descending sort
      }
    });

    setUsageSortedData2(sortedArray);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useMemo(() => {
    setManagementData([...currentData]);
  }, [currentData]);
  useMemo(() => {
    setUsageSortedData([...currentUsageData]);
  }, [currentUsageData]);
  useMemo(() => {
    setUsageSortedData2([...currentUsageData2]);
  }, [currentUsageData2]);
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Subscription Table</Tab>
          <Tab>Interest Table</Tab>
          <Tab>Subscription Usage Table</Tab>
        </TabList>

        <TabPanel>
          {/* User Profile Table */}
          <div className="mt-4">
            <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-[1200px] ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[silver]">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sr. no.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Joined Platform on
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("subscription_start"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Subscription Start Date
                        <span className="ml-2">
                          {sortConfig.key === "subscription_start"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("subscription_end"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Subscription End Date
                        <span className="ml-2">
                          {sortConfig.key === "subscription_end"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Discount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actual Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {managementData?.map((student, index) => (
                    <tr key={index}>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {index + 1 + (currentPage - 1) * rowsPerPage}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {student.name ? student.name.split(" ")[0] : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {student.name
                          ? student.name
                              .split(" ")
                              .slice(1)
                              .join(" ")
                              .replace("|", " ")
                          : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] overfow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {student.email || "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {student.subject_name || "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.subscription_start
                          ? new Date(
                              student.subscription_start
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone:
                                process.env.REACT_APP_TIME_ZONE === "PAK"
                                  ? "Etc/GMT-5"
                                  : "Etc/GMT",
                            })
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.subscription_start
                          ? new Date(
                              student.subscription_start
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone:
                                process.env.REACT_APP_TIME_ZONE === "PAK"
                                  ? "Etc/GMT-5"
                                  : "Etc/GMT",
                            })
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.subscription_end
                          ? new Date(
                              student.subscription_end
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone:
                                process.env.REACT_APP_TIME_ZONE === "PAK"
                                  ? "Etc/GMT-5"
                                  : "Etc/GMT",
                            })
                          : "N/A"}
                      </td>

                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {"£" + student.amount}
                      </td>

                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {student.plan ? student.plan : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {student.discount ? student.discount : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {"£" + student.amount_paid}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className="flex">
                <div
                  style={{
                    display: rowsPerPage === totalData.length ? "none" : "",
                  }}
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`mx-1 px-4 py-2 ${
                      currentPage === 1 ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className="ml-2 mr-2">{currentPage}</span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`mx-1 px-4 py-2 ${
                      currentPage === totalPages ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </div>
                <div>
                  <select
                    id="rowSelector"
                    className="mx-8  rounded"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    <option value="150">150</option>
                    <option value="300">300</option>
                    <option value={totalData.length}>All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="text-center p-4 ">
            <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto w-[1200px]">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-[silver]">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sr. no.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT("creation_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        User interest date
                        <span className="ml-2">
                          {sortConfig2.key === "creation_timestamp"
                            ? sortConfig2.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {usageSortedData.map((user, index) => (
                    <tr key={index}>
                      <td
                        scope="col"
                        className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {usageCurrentPage * usageRowsPerPage -
                          usageRowsPerPage +
                          index +
                          1}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.name ? user.name.split(" ")[0] : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.name
                          .split(" ")
                          .slice(1)
                          .join(" ")
                          .replace("|", " ")
                          ? user.name
                              .split(" ")
                              .slice(1)
                              .join(" ")
                              .replace("|", " ")
                          : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.email}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.subject_name ? user.subject_name : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.creation_timestamp
                          ? new Date(
                              user.creation_timestamp
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone:
                                process.env.REACT_APP_TIME_ZONE === "PAK"
                                  ? "Etc/GMT-5"
                                  : "Etc/GMT",
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className="flex">
                <div
                  style={{
                    display:
                      usageData?.length === usageRowsPerPage ? "none" : "",
                  }}
                >
                  <button
                    onClick={() => handleUsagePageChange(usageCurrentPage - 1)}
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage === 1 ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className="ml-2 mr-2">{usageCurrentPage}</span>
                  <button
                    onClick={() => handleUsagePageChange(usageCurrentPage + 1)}
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage === usageTotalPages
                        ? "bg-gray-500"
                        : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage === usageTotalPages}
                  >
                    {">"}
                  </button>
                </div>
                <select
                  id="rowSelector"
                  className="mx-8  rounded"
                  value={usageRowsPerPage}
                  onChange={handleUsageRowsPerPageChange}
                >
                  <option value="150">150</option>
                  <option value="300">300</option>
                  <option value={usageData.length}>All</option>
                </select>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="text-center p-4 ">
            <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto w-[1200px]">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-[silver]">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sr. no.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Joined Platform on
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT2("subscription_start"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Subscription Start Date
                        <span className="ml-2">
                          {sortConfig3.key === "subscription_start"
                            ? sortConfig3.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT2("subscription_end"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Subscription End Date
                        <span className="ml-2">
                          {sortConfig3.key === "subscription_end"
                            ? sortConfig3.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Discount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actual Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Question Attempts
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Retention Counts (Return of Different Days)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Input Tokens
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Output Tokens
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {usageSortedData2.map((user, index) => (
                    <tr key={index}>
                      <td
                        scope="col"
                        className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {usageCurrentPage * usageRowsPerPage -
                          usageRowsPerPage +
                          index +
                          1}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.name ? user.name.split(" ")[0] : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.name
                          .split(" ")
                          .slice(1)
                          .join(" ")
                          .replace("|", " ")
                          ? user.name
                              .split(" ")
                              .slice(1)
                              .join(" ")
                              .replace("|", " ")
                          : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.email}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {user.subject_name ? user.subject_name : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.creation_date
                          ? new Date(user.creation_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              }
                            )
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.subscription_start
                          ? new Date(
                              user.subscription_start
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone:
                                process.env.REACT_APP_TIME_ZONE === "PAK"
                                  ? "Etc/GMT-5"
                                  : "Etc/GMT",
                            })
                          : "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.subscription_end
                          ? new Date(user.subscription_end).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              }
                            )
                          : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {"£" + user.amount}
                      </td>

                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {user.plan ? user.plan : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {user.discount ? user.discount : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {"£" + user.amount_paid}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {user.question_attempt ? user.question_attempt : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                        onClick={() => handleRowClick(user.days)}
                      >
                        {" "}
                        {user.retention_count ? (
                          <u className="cursor-pointer">
                            {user.retention_count}{" "}
                          </u>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {user.input_token ? user.input_token : "N/A"}
                      </td>
                      <td
                        scope="col"
                        className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {" "}
                        {user.output_token ? user.output_token : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className="flex">
                <div
                  style={{
                    display:
                      usageData2?.length === usageRowsPerPage2 ? "none" : "",
                  }}
                >
                  <button
                    onClick={() =>
                      handleUsagePageChange2(usageCurrentPage2 - 1)
                    }
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage2 === 1 ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage2 === 1}
                  >
                    {"<"}
                  </button>
                  <span className="ml-2 mr-2">{usageCurrentPage2}</span>
                  <button
                    onClick={() => handleUsagePageChange(usageCurrentPage2 + 1)}
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage2 === usageTotalPages2
                        ? "bg-gray-500"
                        : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage2 === usageTotalPages2}
                  >
                    {">"}
                  </button>
                </div>
                <select
                  id="rowSelector"
                  className="mx-8  rounded"
                  value={usageRowsPerPage2}
                  onChange={handleUsageRowsPerPageChange2}
                >
                  <option value="150">150</option>
                  <option value="300">300</option>
                  <option value={usageData2.length}>All</option>
                </select>
              </div>
            </div>
            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                <div className="bg-white p-8 rounded shadow-lg">
                  <table className="min-w-full">
                    <thead className="bg-[silver]">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Question Attempts
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {popupData.map((data, index) => (
                        <tr key={index} className="border border-gray-300">
                          <td
                            scope="col"
                            className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {data.distinct_date
                              ? new Date(data.distinct_date).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    timeZone:
                                      process.env.REACT_APP_TIME_ZONE === "PAK"
                                        ? "Etc/GMT-5"
                                        : "Etc/GMT",
                                  }
                                )
                              : "N/A"}
                          </td>
                          <td
                            scope="col"
                            className="max-w-[300px] px-6 py-3 text-center  text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {data.question_response_count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handlePopupClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>{" "}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Subscription;
