import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="sidebar bg-white text-gray-800 w-64 min-h-screen p-5">
      <h1 className="text-lg font-bold mb-4 mt-2 text-center">
        Admin Dashboard
      </h1>
      <ul>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("mockExperience")}
            className={
              selectedTab === "mockExperience"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Mockbot Experience
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("userProfile")}
            className={
              selectedTab === "userProfile"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Users
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("feedback")}
            className={
              selectedTab === "feedback"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Feedback
          </button>
        </li>
        {/* Add more buttons as needed */}
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("TrailManagement")}
            className={
              selectedTab === "TrailManagement"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Trial Management
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("SubscriptionManagement")}
            className={
              selectedTab === "SubscriptionManagement"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Subscription
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("contact")}
            className={
              selectedTab === "contact"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Contact Us
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("Analysis")}
            className={
              selectedTab === "Analysis"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            User Activity Analysis
          </button>
        </li>
        <li className="mb-3">
          <button
            onClick={() => setSelectedTab("Promo")}
            className={
              selectedTab === "Promo"
                ? "w-full block px-4 py-2 rounded-md bg-blue-500 text-white"
                : "w-full block px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
            }
          >
            Promo Code
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
