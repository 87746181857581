import React, { useEffect, useMemo, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Toggler from "../widgets/Toggler";

const TrialManagement = () => {
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [totalData, setTotalData] = useState([]);
  const [subjects, setSubjects] = useState();
  const [currentUsageData, setCurrentUsageData] = useState([]);
  const [usageCurrentPage, setUsageCurrentPage] = useState(1);
  const [usageRowsPerPage, setUsageRowsPerPage] = useState(150);
  const [usageData, setUsageData] = useState([]);
  const [editSubjectId, setEditSubjectId] = useState(null);
  const [reOrder1, setReOrder1] = useState(true);
  const [reOrder2, setReOrder2] = useState(true);
  const [trialDurations, setTrialDurations] = useState({});
  const [allowedQuestions, setAllowedQuestions] = useState({});
  const [managementData, setManagementData] = useState([]);
  const [usageSortedData, setUsageSortedData] = useState([]);
  const totalPages = Math.ceil(totalData.length / rowsPerPage);
  const usageTotalPages = Math.ceil(usageData.length / usageRowsPerPage);
  const [editTrialDuration, setEditTrialDuration] = useState(false);
  const [editAllowedQuestion, setEditAllowedQuestion] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [statusFilter2, setStatusFilter2] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [sortConfig2, setSortConfig2] = useState({
    key: "",
    direction: "ascending",
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const startIndex = (newPage - 1) * rowsPerPage;
    setCurrentData(totalData.slice(startIndex, startIndex + rowsPerPage));
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setCurrentData(totalData.slice(0, newRowsPerPage));
  };

  const handleUsagePageChange = (newPage) => {
    setUsageCurrentPage(newPage);
    const startIndex = (newPage - 1) * usageRowsPerPage;
    setCurrentUsageData(
      usageData.slice(startIndex, startIndex + usageRowsPerPage)
    );
  };

  const handleUsageRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setUsageRowsPerPage(newRowsPerPage);
    setUsageCurrentPage(1);
    setCurrentUsageData(usageData.slice(0, newRowsPerPage));
  };

  const fetchData = async () => {
    try {
      const payload = {
        current_date: moment().format("Y-MM-DD HH:mm:ss"),
        start_date: null,
        end_date: null,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/fetch_trial_period_data`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("data" + JSON.stringify(response.data.trial_usage_table));
      if (response.data) {
        if (response.data.trial_management_table) {
          const data = Array.isArray(response.data.trial_management_table)
            ? response.data.trial_management_table
            : Object.values(response.data.trial_management_table);
          setTotalData(data);
          setCurrentData(data.slice(0, rowsPerPage));
        }
        if (response.data.trial_usage_table) {
          const usageData = Array.isArray(response.data.trial_usage_table)
            ? response.data.trial_usage_table
            : Object.values(response.data.trial_usage_table);
          setUsageData(usageData);
          setCurrentUsageData(usageData.slice(0, usageRowsPerPage));
        }
      } else {
        console.error("No data returned from the API");
        setTotalData([]);
        setCurrentData([]);
        setUsageData([]);
        setCurrentUsageData([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const getSubjects = async (e) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects_admin`
      );
      console.log("Subjects Here:", response.data);
      setSubjects(response.data);
    } catch (error) {
      console.error("Subjects failed", error.response || error);
    }
  };

  useEffect(() => {
    fetchData();
    getSubjects();
  }, []);
  const toggleStatus = async (status, id, trialD, trialU, allowed_q) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_avalability`,
        {
          availability_status: status === 1 ? 0 : 1,
          mock_subjects_id: id,
          trial_unit: trialU,
          trial_duration: trialD,
          allowed_questions: allowed_q,
        }
      );
      console.log("status successful", response.data);
      getSubjects();
    } catch (error) {
      console.error(
        "status failed",
        error.response ? error.response.data : error
      );
    }
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(subjects);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    // console.log("items here are" + JSON.stringify(items));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subjects_order`,
        {
          list_of_subjects: items,
        }
      );
      console.log(
        "D&D successful" + JSON.stringify(response.data.subject_data)
      );
      setSubjects(response.data.subject_data);
      getSubjects(); // Consider if this needs to run after the update or can be managed through state
    } catch (error) {
      console.error(
        "Update failed",
        error.response ? error.response.data : error
      );
    }
  };

  function getStatusLabel(status) {
    switch (status) {
      case 1:
        return <span className="bg-[orange] text-white">In Progress</span>;
      case 2:
      case 6:
        return <span className="bg-[red] text-white">Expired</span>;
      case 3:
        return <span className="bg-[green] text-white">Subscribed</span>;
      default:
        return <span className="status-na ">N/A</span>;
    }
  }

  // In your render or JSX code

  const updateTrialDurationAndUnit = async (
    status,
    id,
    trialD,
    trialU,
    allowed_q
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_avalability`,
        {
          availability_status: status,
          mock_subjects_id: id,
          trial_unit: trialU,
          trial_duration: trialD,
          allowed_questions: allowed_q,
        }
      );
      console.log("status successful", response.data);
      setTrialDurations({});
      getSubjects();
    } catch (error) {
      console.error(
        "status failed",
        error.response ? error.response.data : error
      );
    }
  };

  const updateAllowedQuestions = async (
    status,
    id,
    trialD,
    trialU,
    allowed_q
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_avalability`,
        {
          availability_status: status,
          mock_subjects_id: id,
          trial_unit: trialU,
          trial_duration: trialD,
          allowed_questions: allowed_q,
        }
      );
      console.log("status successful", response.data);
      getSubjects();
      setEditAllowedQuestion(false);
      setAllowedQuestions({});
    } catch (error) {
      console.error(
        "status failed",
        error.response ? error.response.data : error
      );
      setEditAllowedQuestion(false);
    }
  };

  const sortArrayTMT = (colname) => {
    const isAscending =
      sortConfig.key === colname && sortConfig.direction === "ascending";

    const newDirection = isAscending ? "descending" : "ascending";

    setSortConfig({ key: colname, direction: newDirection });

    const sortedArray = [...managementData].sort((a, b) => {
      const dateA = new Date(a[colname]);
      const dateB = new Date(b[colname]);

      if (newDirection === "ascending") {
        return dateA - dateB; // For ascending sort
      } else {
        return dateB - dateA; // For descending sort
      }
    });

    setManagementData(sortedArray);
  };

  const sortArrayUT = (colname) => {
    const isAscending =
      sortConfig2.key === colname && sortConfig2.direction === "ascending";

    const newDirection = isAscending ? "descending" : "ascending";

    setSortConfig2({ key: colname, direction: newDirection });

    const sortedArray = [...usageSortedData].sort((a, b) => {
      const dateA = new Date(a[colname]);
      const dateB = new Date(b[colname]);

      if (newDirection === "ascending") {
        return dateA - dateB; // For ascending sort
      } else {
        return dateB - dateA; // For descending sort
      }
    });

    setUsageSortedData(sortedArray);
  };

  useEffect(() => {
    fetchData();
    console.log("data here" + subjects);
  }, []);
  useMemo(() => {
    setManagementData([...currentData]);
  }, [currentData]);
  useMemo(() => {
    setUsageSortedData([...currentUsageData]);
  }, [currentUsageData]);
  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Trial Management Table</Tab>
          <Tab>Usage Table</Tab>
          <Tab>Trial Configurations</Tab>
        </TabList>

        <TabPanel>
          {/* User Profile Table */}
          <div className="mt-4">
            <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[silver]">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sr. no.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-7  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("creation_date"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        Joined Platform on{" "}
                        <span className="ml-2">
                          {sortConfig.key === "creation_date"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("trial_start_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        Trial Start Date
                        <span className="ml-2">
                          {sortConfig.key === "trial_start_timestamp"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("trial_end_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Trial End Date
                        <span className="ml-2">
                          {sortConfig.key === "trial_end_timestamp"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayTMT("subscription_start_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        {" "}
                        Subscription Start Date
                        <span className="ml-2">
                          {sortConfig.key === "subscription_start_timestamp"
                            ? sortConfig.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                      <select
                        id="statusFilter"
                        className="mt-2 ml-2 form-select border border-gray-300 rounded"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="1">In Progress</option>
                        <option value="2">Expired</option>
                        <option value="3">Subscribed</option>
                        <option value="null">N/A</option>
                      </select>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {managementData
                    ?.filter((student) => {
                      if (statusFilter === "") return true; // No filter applied
                      if (statusFilter === "null")
                        return String(student.account_status) === statusFilter;
                      // Filter for null or undefined statuses
                      return String(student.account_status) === statusFilter; // Filter for specific status
                    })
                    .map((student, index) => (
                      <tr key={index}>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {index + 1 + (currentPage - 1) * rowsPerPage}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {student.name ? student.name.split(" ")[0] : "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {student.name
                            ? student.name
                                .split(" ")
                                .slice(1)
                                .join(" ")
                                .replace("|", " ")
                            : "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {student.email || "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {student.subject_name || "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {student.creation_date
                            ? new Date(
                                student.creation_date
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {student.trial_start_timestamp
                            ? new Date(
                                student.trial_start_timestamp
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {student.trial_end_timestamp
                            ? new Date(
                                student.trial_end_timestamp
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {student.subscription_start_timestamp
                            ? new Date(
                                student.subscription_start_timestamp
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>

                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {" "}
                          {getStatusLabel(student.account_status)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className="flex">
                <div
                  style={{
                    display: rowsPerPage === totalData.length ? "none" : "",
                  }}
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`mx-1 px-4 py-2 ${
                      currentPage === 1 ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className="ml-2 mr-2">{currentPage}</span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`mx-1 px-4 py-2 ${
                      currentPage === totalPages ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </div>
                <div>
                  <select
                    id="rowSelector"
                    className="mx-8  rounded"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    <option value="150">150</option>
                    <option value="300">300</option>
                    <option value={totalData.length}>All</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="text-center p-4 ">
            <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto w-[1200px]">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-[silver]">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sr. no.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subject
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT("creation_date"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        Joined Platform on{" "}
                        <span className="ml-2">
                          {sortConfig2.key === "creation_date"
                            ? sortConfig2.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT("trial_start_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        Trial Start Date
                        <span className="ml-2">
                          {sortConfig2.key === "trial_start_timestamp"
                            ? sortConfig2.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span
                        className="cursor-pointer w-full"
                        onClick={() => {
                          sortArrayUT("trial_end_timestamp"); // Assuming "creation_date" is the correct column name
                        }}
                      >
                        Trial End Date
                        <span className="ml-2">
                          {sortConfig2.key === "trial_end_timestamp"
                            ? sortConfig2.direction === "ascending"
                              ? "🢁"
                              : "🢃"
                            : "🢃"}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                      <select
                        id="statusFilter"
                        className="mt-2 ml-2 form-select border border-gray-300 rounded"
                        value={statusFilter2}
                        onChange={(e) => setStatusFilter2(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="1">In Progress</option>
                        <option value="2">Expired</option>
                        <option value="3">Subscribed</option>
                      </select>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Question Attempts
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Token Input
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Token Output
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Latest Date{" "}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {usageSortedData
                    ?.filter((student) => {
                      if (statusFilter2 === "") return true; // No filter applied
                      if (statusFilter2 === "null")
                        return (
                          student.account_status === null ||
                          student.account_status === undefined
                        ); // Filter for null or undefined statuses
                      return String(student.account_status) === statusFilter2; // Filter for specific status
                    })
                    ?.map((user, index) => (
                      <tr key={index}>
                        <td
                          scope="col"
                          className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {usageCurrentPage * usageRowsPerPage -
                            usageRowsPerPage +
                            index +
                            1}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {user.name ? user.name.split(" ")[0] : "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {user.name
                            .split(" ")
                            .slice(1)
                            .join(" ")
                            .replace("|", " ")
                            ? user.name
                                .split(" ")
                                .slice(1)
                                .join(" ")
                                .replace("|", " ")
                            : "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {user.email}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {user.subject_name ? user.subject_name : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.creation_date
                            ? new Date(user.creation_date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  timeZone:
                                    process.env.REACT_APP_TIME_ZONE === "PAK"
                                      ? "Etc/GMT-5"
                                      : "Etc/GMT",
                                }
                              )
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.trial_start_timestamp
                            ? new Date(
                                user.trial_start_timestamp
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.trial_end_timestamp
                            ? new Date(
                                user.trial_end_timestamp
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                timeZone:
                                  process.env.REACT_APP_TIME_ZONE === "PAK"
                                    ? "Etc/GMT-5"
                                    : "Etc/GMT",
                              })
                            : "N/A"}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {" "}
                          {getStatusLabel(user.account_status)}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                        >
                          {" "}
                          {user.trial_period_question_usage}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                        >
                          {" "}
                          {user.input_token}
                        </td>
                        <td
                          scope="col"
                          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                        >
                          {" "}
                          {user.output_token}
                        </td>
                        <td className="px-6 py-4 overflow-x-auto w-[200px] whitespace-nowrap text-sm text-gray-500">
                          {Array.isArray(user.creation_dates) ? (
                            user.creation_dates.length > 0 ? (
                              [
                                ...new Set(
                                  user.creation_dates.map((date) =>
                                    new Date(date).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      timeZone:
                                        process.env.REACT_APP_TIME_ZONE ===
                                        "PAK"
                                          ? "Etc/GMT-5"
                                          : "Etc/GMT",
                                    })
                                  )
                                ),
                              ].map((formattedDate, index, array) => (
                                <span key={index}>
                                  {formattedDate}
                                  {index !== array.length - 1 ? ", " : ""}
                                </span>
                              ))
                            ) : (
                              <span>N/A</span>
                            )
                          ) : (
                            <span>
                              {user.creation_dates
                                ? new Date(
                                    user.creation_dates
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    timeZone:
                                      process.env.REACT_APP_TIME_ZONE === "PAK"
                                        ? "Etc/GMT-5"
                                        : "Etc/GMT",
                                  })
                                : "N/A"}
                            </span>
                          )}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.latest_date
                            ? new Date(user.latest_date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  timeZone:
                                    process.env.REACT_APP_TIME_ZONE === "PAK"
                                      ? "Etc/GMT-5"
                                      : "Etc/GMT",
                                }
                              )
                            : "N/A"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className="flex">
                <div
                  style={{
                    display:
                      usageData?.length === usageRowsPerPage ? "none" : "",
                  }}
                >
                  <button
                    onClick={() => handleUsagePageChange(usageCurrentPage - 1)}
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage === 1 ? "bg-gray-500" : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className="ml-2 mr-2">{usageCurrentPage}</span>
                  <button
                    onClick={() => handleUsagePageChange(usageCurrentPage + 1)}
                    className={`mx-1 px-4 py-2 ${
                      usageCurrentPage === usageTotalPages
                        ? "bg-gray-500"
                        : "bg-blue-500"
                    } text-white`}
                    disabled={usageCurrentPage === usageTotalPages}
                  >
                    {">"}
                  </button>
                </div>
                <select
                  id="rowSelector"
                  className="mx-8  rounded"
                  value={usageRowsPerPage}
                  onChange={handleUsageRowsPerPageChange}
                >
                  <option value="150">150</option>
                  <option value="300">300</option>
                  <option value={usageData.length}>All</option>
                </select>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          {" "}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="subjects">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="p-4"
                >
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-200 text-gray-700">
                      <tr>
                        <th className="px-4 py-2 text-left font-medium rounded-tl-lg">
                          Subject
                        </th>
                        <th className="px-4 py-2 text-left font-medium">
                          Trial Duration
                        </th>
                        <th className="px-4 py-2 text-left font-medium">
                          Trial Unit
                        </th>
                        <th className="px-4 py-2 text-left font-medium">
                          Allowed Questions
                        </th>
                        <th className="px-4 py-2 text-left font-medium rounded-tr-lg">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjects?.map((subject, index) => (
                        <Draggable
                          key={subject.mock_subjects_id}
                          draggableId={String(subject.mock_subjects_id)}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="bg-white"
                            >
                              <td className="p-4 border-b border-gray-300">
                                {subject.subject_name}
                              </td>
                              <td className="p-4 border-b border-gray-300">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    setEditTrialDuration(false);
                                    setEditSubjectId(null);
                                    updateTrialDurationAndUnit(
                                      subject.availability_status,
                                      subject.mock_subjects_id,
                                      trialDurations[
                                        subject.mock_subjects_id
                                      ] || subject.trial_duration,
                                      subject.trial_unit,
                                      subject.allowed_questions
                                    );
                                  }}
                                  className="flex items-center"
                                >
                                  <input
                                    type="number"
                                    min="1"
                                    max="7"
                                    placeholder="Trial Duration"
                                    className="form-input border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md shadow-sm px-3 py-2 mr-2"
                                    value={
                                      trialDurations[
                                        subject.mock_subjects_id
                                      ] || subject.trial_duration
                                    }
                                    onChange={(e) => {
                                      setEditTrialDuration(true);
                                      setEditSubjectId(
                                        subject.mock_subjects_id
                                      );
                                      setTrialDurations({
                                        ...trialDurations,
                                        [subject.mock_subjects_id]:
                                          e.target.value,
                                      });
                                    }}
                                  />
                                  <button
                                    type="submit"
                                    style={{
                                      display:
                                        editTrialDuration &&
                                        editSubjectId ===
                                          subject.mock_subjects_id
                                          ? ""
                                          : "none",
                                    }}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                                  >
                                    &#10003;
                                  </button>
                                  <button
                                    type="button"
                                    style={{
                                      display:
                                        editTrialDuration &&
                                        editSubjectId ===
                                          subject.mock_subjects_id
                                          ? ""
                                          : "none",
                                    }}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => {
                                      setEditTrialDuration(false);
                                      setEditSubjectId(null);
                                      setTrialDurations({
                                        ...trialDurations,
                                        [subject.mock_subjects_id]:
                                          subject.trial_duration,
                                      });
                                    }}
                                  >
                                    &#10005;
                                  </button>
                                </form>
                              </td>
                              <td className="p-4 border-b border-gray-300">
                                <select
                                  className="form-select border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md shadow-sm px-3 py-2 mr-2"
                                  value={subject.trial_unit}
                                  onChange={(e) => {
                                    updateTrialDurationAndUnit(
                                      subject.availability_status,
                                      subject.mock_subjects_id,
                                      trialDurations[
                                        subject.mock_subjects_id
                                      ] || subject.trial_duration,
                                      e.target.value,
                                      subject.allowed_questions
                                    );
                                  }}
                                >
                                  <option value="1">Days</option>
                                  <option value="0">Minutes</option>
                                </select>
                              </td>
                              <td className="p-4 border-b border-gray-300">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    setEditSubjectId(null);
                                    updateAllowedQuestions(
                                      subject.availability_status,
                                      subject.mock_subjects_id,
                                      subject.trial_duration,
                                      subject.trial_unit,
                                      allowedQuestions[
                                        subject.mock_subjects_id
                                      ] || subject.allowed_questions
                                    );
                                  }}
                                  className="flex items-center"
                                >
                                  <input
                                    type="number"
                                    min="1"
                                    max="20"
                                    placeholder="Allowed Questions"
                                    className="form-input border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md shadow-sm px-3 py-2 mr-2"
                                    value={
                                      allowedQuestions[
                                        subject.mock_subjects_id
                                      ] || subject.allowed_questions
                                    }
                                    onChange={(e) => {
                                      setEditAllowedQuestion(true);
                                      setEditSubjectId(
                                        subject.mock_subjects_id
                                      );
                                      setAllowedQuestions({
                                        ...allowedQuestions,
                                        [subject.mock_subjects_id]:
                                          e.target.value,
                                      });
                                    }}
                                  />
                                  <button
                                    type="submit"
                                    style={{
                                      display:
                                        editAllowedQuestion &&
                                        editSubjectId ===
                                          subject.mock_subjects_id
                                          ? ""
                                          : "none",
                                    }}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                  >
                                    &#10003;
                                  </button>
                                  <button
                                    type="button"
                                    style={{
                                      display:
                                        editAllowedQuestion &&
                                        editSubjectId ===
                                          subject.mock_subjects_id
                                          ? " "
                                          : "none",
                                    }}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                                    onClick={() => {
                                      setEditAllowedQuestion(false);
                                      setEditSubjectId(null);
                                      setAllowedQuestions({
                                        ...allowedQuestions,
                                        [subject.mock_subjects_id]:
                                          subject.allowed_questions,
                                      });
                                    }}
                                  >
                                    &#10005;
                                  </button>
                                </form>
                              </td>
                              <td className="p-4 border-b border-gray-300">
                                <Toggler
                                  isEnabled={subject.availability_status}
                                  onToggle={() =>
                                    toggleStatus(
                                      subject.availability_status,
                                      subject.mock_subjects_id,
                                      subject.trial_duration,
                                      subject.trial_unit,
                                      subject.allowed_questions
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TrialManagement;
