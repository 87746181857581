import React, { useState } from "react";
// import Header from "../Header";
import AIIcon from "../../images/pricing_1.png";
import QuestionsIcon from "../../images/pricing_2.png";
import SpecsIcon from "../../images/pricing_3.png";
import month12 from "../../images/12_month.png";
import month3 from "../../images/3_month.png";
import month1 from "../../images/month.png";
import percent_38 from "../../images/38.png";
import percent_50 from "../../images/50.png";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
const WordpressPricingPage = () => {
  const navigate = useNavigate();
  const [productId, setProductId] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [plan, setPlan] = useState();
  const getproduct = async (plans, amount) => {
    if (plans === "4") {
      ReactGA.event("plan_visits", {
        plan_visit: "Monthly",
      });
    }
    if (plans === "5") {
      ReactGA.event("plan_visits", {
        plan_visit: "Tri-Monthly",
      });
    }
    if (plans === "6") {
      ReactGA.event("plan_visits", {
        plan_visit: "Yearly",
      });
    }
    window.open("https://app.mockbot.co/login", "_blank", "noopener");
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container mx-auto px-2 md:px-4 py-8 ">
        <h1
          className="text-[40px] font-bold  text-center text-[#000000]"
          style={{ lineHeight: "56px", fontFamily: "Roboto" }}
        >
          Kickstart Your Academic Journey with MockBot
        </h1>
        <p
          className="mt-2 mb-8 text-center text-[#000000] text-[22px]"
          style={{ lineHeight: "28px", fontFamily: "Roboto" }}
        >
          Score top grades and ace exams with MockBot — select a single-subject
          package tailored for success.
        </p>
        <div className="flex flex-col items-center justify-center md:flex-row gap-4 ">
          {/* Yearly Plan */}

          <div
            id="3months"
            onClick={() => getproduct("5", 15)}
            className="relative rounded-lg shadow-lg p-6 transition duration-300 border border-gray-200 cursor-pointer w-[410px] h-[276px]"
            style={{
              background: "linear-gradient(180deg, #F0F2F5 0%, #D2D2D2 100%)",
            }}
          >
            <p
              className="flex   font-bold mb-2 w-full"
              style={{
                lineHeight: "28px",
                fontFamily: "Roboto",
                color: "#18181B",
              }}
            >
              <img className="w-[32px] h-[30px] mr-2" src={month3} alt="" />
              <span className="w-full whitespace-nowrap text-[1.4rem] mr-2">
                3 Months Plan{" "}
              </span>
              <img
                className="w-[72px] h-[20px] ml-auto "
                src={percent_38}
                alt=""
              />
            </p>
            <center>
              <span
                className="text-[48px]   font-bold text-[#18181B] "
                style={{ lineHeight: "56px", fontFamily: "Roboto" }}
              >
                £5
              </span>

              <span
                className="text-[14px]"
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                per month
              </span>
            </center>

            <div
              className="text-[14px] text-center text-[#18181B]"
              style={{
                lineHeight: "20px",
                letterSpacing: "0.1px",
                fontFamily: "Roboto",
              }}
            >
              <ul
                style={{
                  listStyleType: "none", // Removes default list styling
                  padding: 0, // Removes default padding
                  margin: "0 auto", // Centers the list horizontally and removes vertical margin
                  width: "fit-content", // Minimizes width to the content's natural width
                  textAlign: "left", // Aligns text to the left within each list item
                }}
              >
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>{" "}
                  Billed every 3 months at £15
                </li>
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>{" "}
                  Cancel anytime
                </li>
              </ul>
            </div>
            <button
              id="button3"
              onMouseEnter={() => {
                document.getElementById("button3").style.background =
                  "linear-gradient(180deg, #18181B 0%, #4A4A4A 100%)";
              }}
              onMouseLeave={() => {
                document.getElementById("button3").style.background = "";
              }}
              onClick={() => getproduct("5", 15)}
              className="w-full flex mt-[40px] bg-[FFD015] hover:bg-[#4A4A4A] text-[#322E33] border-[#4A4A4A] border-2 hover:text-white font-bold py-3 px-6 rounded transition duration-300 justify-center"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              Choose Plan{" "}
              {isSubmiting && plan === "5" && (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline ml-[150px] mt-1 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            id="12months"
            onClick={() => getproduct("6", 48)}
            className="relative rounded-lg shadow-lg p-6 transition duration-300 border border-gray-200 cursor-pointer w-[410px] h-[276px]"
            style={{
              background: "linear-gradient(180deg, #FFDA46 0%, #FFD015 100%)",
            }}
          >
            <p
              className="flex text-[24px]  font-bold mb-2 w-full"
              style={{
                lineHeight: "28px",
                fontFamily: "Roboto",
                color: "#18181B",
              }}
            >
              <img className="w-[32px] h-[30px] mr-2" src={month12} alt="" />
              <span className="w-full whitespace-nowrap text-[1.3rem] mr-2">
                12 Months Plan{" "}
              </span>
              <img
                className="w-[72px] h-[20px] ml-auto "
                src={percent_50}
                alt=""
              />
            </p>
            <center>
              <span
                className="text-[48px]  font-bold text-[#18181B] "
                style={{ lineHeight: "56px", fontFamily: "Roboto" }}
              >
                £4
              </span>

              <span
                className="text-[14px]"
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                per month
              </span>
            </center>

            <div
              className="text-[14px] text-center text-[#18181B]"
              style={{
                lineHeight: "20px",
                letterSpacing: "0.1px",
                fontFamily: "Roboto",
              }}
            >
              <ul
                style={{
                  listStyleType: "none", // Removes default list styling
                  padding: 0, // Removes default padding
                  margin: "0 auto", // Centers the list horizontally and removes vertical margin
                  width: "fit-content", // Minimizes width to the content's natural width
                  textAlign: "left", // Aligns text to the left within each list item
                }}
              >
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>{" "}
                  Billed yearly at £48
                </li>
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>{" "}
                  Cancel anytime
                </li>
              </ul>
            </div>
            <button
              id="button12"
              onClick={() => getproduct("6", 48)}
              className="w-full flex  mt-[40px] hover:bg-[FFD015] bg-[#4A4A4A] hover:bg-transparent hover:text-[#322E33] border-[#4A4A4A] border-2 text-white font-bold py-3 px-6 rounded transition duration-300 justify-center"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Choose Plan{" "}
              {isSubmiting && plan === "6" && (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline   ml-[150px] mt-1 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
          <div
            id="month"
            onClick={() => getproduct("4", 8)}
            className="relative rounded-lg shadow-lg p-6 transition duration-300 border border-gray-200 cursor-pointer w-[410px] h-[276px]"
            style={{
              background: "linear-gradient(180deg, #F0F2F5 0%, #D2D2D2 100%)",
            }}
          >
            <p
              className="flex text-[24px]  font-bold mb-2 w-full"
              style={{
                lineHeight: "28px",
                fontFamily: "Roboto",
                color: "#18181B",
              }}
            >
              <img className="w-[32px] h-[30px] mr-2" src={month1} alt="" />
              <span className="w-full whitespace-nowrap text-[1.3rem]">
                Monthly Plan{" "}
              </span>
              <img
                className="w-[72px] h-[20px] ml-auto "
                style={{ visibility: "hidden" }}
                src={percent_50}
                alt=""
              />
            </p>
            <center>
              <span
                className="text-[48px]  font-bold text-[#18181B] "
                style={{ lineHeight: "56px", fontFamily: "Roboto" }}
              >
                £8
              </span>

              <span
                className="text-[14px]"
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                per month
              </span>
            </center>
            <div
              className="text-[14px] text-center text-[#18181B]"
              style={{
                lineHeight: "20px",
                letterSpacing: "0.1px",
                fontFamily: "Roboto",
              }}
            >
              <ul
                style={{
                  listStyleType: "none", // Removes default list styling
                  padding: 0, // Removes default padding
                  margin: "0 auto", // Centers the list horizontally and removes vertical margin
                  width: "fit-content", // Minimizes width to the content's natural width
                  textAlign: "left", // Aligns text to the left within each list item
                }}
              >
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>{" "}
                  Billed monthly
                </li>
                <li
                  style={{
                    display: "flex", // Uses flexbox for layout
                    alignItems: "center", // Aligns items vertically in the center
                  }}
                >
                  <b style={{ marginRight: "10px", fontSize: "24px" }}>• </b>
                  Cancel anytime
                </li>
              </ul>
            </div>

            <button
              id="button1"
              onMouseEnter={() => {
                document.getElementById("button1").style.background =
                  "linear-gradient(180deg, #18181B 0%, #4A4A4A 100%)";
              }}
              onMouseLeave={() => {
                document.getElementById("button1").style.background = "";
              }}
              onClick={() => getproduct("4", 8)}
              className="w-full flex  mt-[40px] bg-[FFD015] hover:bg-[#4A4A4A] text-[#322E33] border-[#4A4A4A] border-2 hover:text-white font-bold py-3 px-6 rounded transition duration-300 justify-center"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              Choose Plan{" "}
              {isSubmiting && plan === "4" && (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline ml-[150px] mt-1 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="text-center mt-16">
          <h2
            className="text-[36px] font-bold mb-8 text-[#322E33]"
            style={{ lineHeight: "44px", fontFamily: "Roboto" }}
          >
            Why Choose MockBot?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center justify-center">
            <div className="flex flex-col items-center justify-center   w-[320px]">
              <img
                src={AIIcon}
                alt="AI Icon"
                className="w-[68px] h-[75px] mr-4 mb-4"
              />
              <div>
                <h3
                  className="text-[22px] font-bold mb-2"
                  style={{
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                    color: "#000000",
                  }}
                >
                  Unlimited AI feedback
                </h3>
                <p
                  className="text-[#000000]"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "26px",
                  }}
                >
                  Instant, personalised feedback with our cutting-edge AI
                  technology.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center  w-[320px] ">
              <img
                src={QuestionsIcon}
                alt="Questions Icon"
                className="w-[100px] h-[100px] mr-4 mb-4"
              />
              <div>
                <h3
                  className="text-[22px] font-bold mb-2"
                  style={{
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                    color: "#000000",
                  }}
                >
                  Up to 500 exam-style questions per month
                </h3>
                <p
                  className="text-[#000000]"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "26px",
                  }}
                >
                  Challenge yourself with up to 500 tailored questions per
                  month.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center  w-[320px]">
              <img
                src={SpecsIcon}
                alt="Specs Icon"
                className="w-[110px] h-[82px] mr-4 mb-4"
              />
              <div>
                <h3
                  className="text-[22px] font-bold mb-2"
                  style={{
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                    color: "#000000",
                  }}
                >
                  Expert-curated questions aligned with exam specs
                </h3>
                <p
                  className="text-[#000000]"
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "26px",
                  }}
                >
                  Questions curated by experts to align perfectly with exam
                  specifications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WordpressPricingPage;
