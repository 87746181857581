import React, { useEffect, useState } from "react";
import Header from "../../../Header";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import icon1 from "../../../../images/Icons.png";
import icon2 from "../../../../images/Icons_2.png";
import icon3 from "../../../../images/icons_3.png";
import icon4 from "../../../../images/icons_4.png";
import right_arrow from "../../../../images/arrow_right_alt.png";
import { loadStripe } from "@stripe/stripe-js";
import ProgressBar from "../widgets/ProgressBar ";
import TrialExpiredIcon from "../../../../images/trial-expired-icon.png";
import TrialInProgressIcon from "../../../../images/trial-inprogress-icon.png";
import SubscribedIcon from "../../../../images/subscribed-icon.png";
import UnSubscribedIcon from "../../../../images/unsubscribed-icon.png";
import TrialNotStartedIcon from "../../../../images/trial-notstarted-icon.png";
const Settings = () => {
  const [isSubmiting, setisSubmiting] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const fetchConfig = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/config`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const { publishableKey } = await response.json();
        setStripePromise(loadStripe(publishableKey));
      } else {
        console.error("Failed to fetch config");
      }
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  };

  const createPaymentIntent = async () => {
    try {
      setisSubmiting(true);

      const mail = localStorage.getItem("email_address");

      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/create_customer_portal_session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: mail }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        //       // Set the session ID in the state

        console.log("session id is" + data);
        // Redirect the user to the Stripe Checkout page
        window.location.href = data;
        setisSubmiting(false);
      } else {
        console.error("Failed to create payment intent");
        setisSubmiting(false);
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };
  const handleUpgrade = async (id, name, trial_id) => {
    const token = localStorage.getItem("access_token");
    localStorage.setItem("subject_id", id);
    localStorage.setItem("subject_name", name);
    localStorage.setItem("trial_id", trial_id);
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/submit_buy_interest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          subject_id: id,
          subject_name: name,
        }),
      }
    );
    if (response.ok) {
      window.open("/pricing");

      // navigate("/pricing");
    } else {
      console.error("Failed");
    }
  };
  const getProfileData = async () => {
    const token = localStorage.getItem("access_token");
    const mail = localStorage.getItem("email_address");
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/get_profile_data`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          email: mail,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();

      // Assuming each item in data.data has a subject_list you want to display
      const subjectsFromData = data.data.flatMap((item) =>
        item.subject_list ? item.subject_list : []
      );
      setSubjects(subjectsFromData);
      if (data.data.length > 0) {
      }
      console.log("subjects set:", subjectsFromData);
    } else {
      console.error("Failed to fetch subjects");
    }
  };
  useEffect(() => {
    fetchConfig();
    getProfileData();
  }, []);

  const icons = [icon1, icon2, icon3, icon4]; // Array of your icons

  const handleTextColor = (status) => {
    if (!status) {
      return "#515151";
    }
    if (status === 2 || status === 6) {
      return "#4A1006";
    } else if (status === 1) {
      return "#352D03";
    } else if (status === 3) {
      return "063C06";
    }
  };
  const handleStatusIcons = (status) => {
    if (!status) {
      return TrialNotStartedIcon;
    }
    if (status === 2 || status === 6) {
      return TrialExpiredIcon;
    } else if (status === 1) {
      return TrialInProgressIcon;
    } else if (status === 3) {
      return SubscribedIcon;
    }
  };
  return (
    <>
      <Header />
      <div className="flex flex-col items-center w-full">
        <div
          className="py-[30px] md:pl-[220px] sm:pl-[36px] bg-[#444780] text-white w-full font-extrabold"
          style={{ fontFamily: "Roboto", fontSize: "36px", lineHeight: "44px" }}
        >
          <p
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "24px",
              color: "#D9DBFF",
              width: "contain",
              // Change cursor to pointer to indicate it's clickable
            }}
            // Navigate to "/"
          >
            <span style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
              {" "}
              Home
            </span>
          </p>{" "}
          Settings
        </div>
        <div className=" md:w-[70%] sm:w-[100%] bg-white shadow mt-[80px] mb-8 mx-auto">
          {/* Tabs */}

          {/* Tab Content */}
          <div className="p-4">
            <div>
              <div className="flex justify-between   items-center mt-8  bg-gray-100 rounded-tl-[4px] rounded-tr-[4px]">
                <h2
                  className="text-lg font-semibold text-gray-700  py-2 px-4 rounded-l-lg mb-[26px] mt-[18px]"
                  style={{ fontFamily: "Roboto" }}
                >
                  My Subscriptions
                </h2>
                <button
                  onClick={() => {
                    createPaymentIntent();
                  }}
                  className="w-auto mr-2 float right flex items-center justify-center rounded-md py-2 px-6 font-bold transition ease-in-out hover:opacity-50 duration-300 border-2 border-[#444780] text-[#444780] "
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                  }}
                >
                  Manage Subscription
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    className="ml-2 "
                    viewBox="0 0 30 30"
                    style={{ fill: "#444780", fontWeight: "bold" }}
                  >
                    {/* SVG path here */}
                    <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path>
                  </svg>{" "}
                  {isSubmiting && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline   ml-4 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="#444780"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#444780"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="bg-[#F2F2F2] rounded-lg overflow-x-auto ">
                <table className="min-w-full  text-left">
                  <thead className="bg-[#E0E0E0] ">
                    <tr>
                      <th
                        className="px-4 py-3 border-b-2 border-gray-200 text-gray-600"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        S.No
                      </th>
                      <th
                        className="px-4 py-3 border-b-2 border-gray-200 text-gray-600"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Subject
                      </th>
                      <th
                        className="px-4 py-3 border-b-2 border-gray-200 text-gray-600"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Enrolment date
                      </th>
                      <th
                        className="px-4 py-3 border-b-2 border-gray-200 text-gray-600 text-center"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Status
                      </th>
                      <th className="px-4 py-3 border-b-2 border-gray-200"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects.map((subject, index) => (
                      <tr key={index} className="bg-[#F2F2F2]">
                        <td
                          className="px-6 py-10 border-b border-gray-200 font-medium text-gray-700  items-center"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="px-6 py-10 border-b border-gray-200 font-medium text-gray-700 flex items-center"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {/* Insert the icon next to the subject name */}
                          <img
                            src={icons[index % icons.length]}
                            alt="Icon"
                            className="mr-2 h-[32px] w-[32px]"
                          />
                          <div>{subject.subject_name}</div>
                        </td>
                        <td
                          className="px-4 py-2 border-b border-gray-200 text-gray-500"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {new Date(
                            subject.creation_timestamp
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            timeZone: "Etc/GMT",
                          })}
                        </td>
                        <td
                          className="px-6 py-3 border-b border-gray-200 text-center"
                          style={{
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            color: "#464646",
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          <span
                            className={`flex pb-1 font-medium text-[12px] ${handleTextColor(
                              subject.trial_data[0]?.account_status
                            )} `}
                            style={{
                              backgroundColor: !subject.trial_data[0]
                                ?.account_status
                                ? "#DAD4DD"
                                : subject.trial_data[0]?.account_status === 2 ||
                                  subject.trial_data[0]?.account_status === 6
                                ? "#F8ADA0"
                                : subject.trial_data[0]?.account_status === 1
                                ? "#F8E57E"
                                : subject.trial_data[0]?.account_status === 3
                                ? "#A5E3A4"
                                : "none",
                              borderRadius: "8px",
                              padding: "8px",
                              maxWidth: "fit-content",
                            }}
                          >
                            <span>
                              <img
                                src={handleStatusIcons(
                                  subject.trial_data[0]?.account_status
                                )}
                                style={{ width: "16px", height: "16px" }}
                              />
                            </span>
                            <span
                              style={{
                                marginLeft: "8px",
                                marginRight: "2px",
                              }}
                            >
                              {!subject.trial_data[0]?.account_status
                                ? "Trial Not Started"
                                : subject.trial_data[0]?.account_status === 1
                                ? "Trial In Progress"
                                : subject.trial_data[0]?.account_status === 2
                                ? "Trial Expired"
                                : subject.trial_data[0]?.account_status === 3
                                ? `Subscribed`
                                : subject.trial_data[0]?.account_status === 6
                                ? "Expired"
                                : "Subscription expired"}
                            </span>
                          </span>
                          <p className="mt-4 text-start">
                            {subject.trial_data[0]?.account_status === 3 &&
                              ` Till ${new Date(
                                subject.trial_data[0]?.subscription_end_timestamp
                              ).toLocaleDateString()}`}

                            {subject.trial_data[0]?.account_status === 2 &&
                              subject.trial_data[0]?.days_left <= 0 &&
                              `on ${new Date(
                                subject.trial_data[0]?.trial_end_timestamp
                              ).toLocaleDateString()}`}
                          </p>
                        </td>

                        <td className="px-6 py-3 border-b border-gray-200 text-left">
                          <button
                            className={`w-[180px] rounded py-2 px-6 text-sm transition ease-in-out duration-300 ${
                              subject.trial_data[0]?.account_status === 2 ||
                              subject.trial_data[0]?.account_status === 6 ||
                              subject.trial_data[0]?.account_status === 1 ||
                              !subject.trial_data[0]?.account_status
                                ? "bg-[#F8F9FE] text-[#444780]  border border-[#444780] hover:opacity-45 "
                                : "bg-[#444780] text-white hover:opacity-45"
                            }`}
                            onClick={
                              subject.trial_data[0]?.account_status === 2 ||
                              subject.trial_data[0]?.account_status === 6 ||
                              subject.trial_data[0]?.account_status === 1 ||
                              !subject.trial_data[0]?.account_status
                                ? () => {
                                    handleUpgrade(
                                      subject.subject_id,
                                      subject.subject_name,
                                      subject.trial_data.length > 0
                                        ? subject.trial_data[0]?.trial_period_id
                                        : "upgrade"
                                    );
                                  }
                                : () => {
                                    navigate("/");
                                  }
                            }
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                              display:
                                subject.trial_data[0]?.account_status === 2 ||
                                subject.trial_data[0]?.account_status === 6 ||
                                subject.trial_data[0]?.account_status === 1 ||
                                !subject.trial_data[0]?.account_status
                                  ? ""
                                  : "none",
                            }}
                          >
                            {subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 6 ||
                            subject.trial_data[0]?.account_status === 1 ||
                            !subject.trial_data[0]?.account_status
                              ? "⚡ Upgrade now!"
                              : "Start practicing"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
