import React, { useEffect } from "react";

import LoginPageRightSide from "./LoginPageRightPart";
import LoginPageLeftSide from "./LoginPageLeftSide";
import amplitude from "amplitude-js";
import ReactGA from "react-ga4";
function Login() {
  useEffect(() => {
    amplitude.getInstance().logEvent("Login page view");
    ReactGA.event("Login Page View", {
      login_page_view: "Login Page View",
    });
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden ">
          <LoginPageLeftSide />
          <LoginPageRightSide header="Ace your grades - join MockBot now!" />
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
