import React, { useEffect, useState } from "react";
import axios from "axios";
const AnalysisTable = () => {
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rau, setRau] = useState([]);
  const [avgDau, setAvgDau] = useState([]);
  const [rauU, setRauU] = useState([]);
  const [data, setData] = useState([]);
  const [emailPopup, setEmailPopup] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const handleSubmit = () => {
    fetchData(startDate, endDate); // Fetch data based on the selected dates
    setIsModalOpen(false); // Close modal after submitting
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchData = async (start, end) => {
    try {
      // Create a new date object for the start date (current date and time)

      const payload = {
        start_date: start,
        end_date: end,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_user_activity`,
        payload
      );
      console.log(response.data); // Make sure this is an array
      setRau(response.data?.rau_list);
      setAvgDau(response.data?.avg_dau_u_list);
      setRauU(response.data?.rau_u_list);
      setData(response.data?.dau_list);
      // Adjust the following line according to the structure of response.data
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const handleEmailClick = (emails) => {
    setSelectedEmails(emails); // Assuming each row data has 'emails' array
    setEmailPopup(true);
  };

  const closeEmailPopup = () => setEmailPopup(false);
  useEffect(() => {
    fetchData(startDate, endDate);
  }, []);
  return (
    <div className="container mx-auto p-4">
      <button
        onClick={openModal}
        className="mb-4 bg-gray-200 hover:bg-gray-400 text-gray-900  py-2 px-4 rounded"
      >
        Select Dates
      </button>

      {/* Date Selection Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Select Date Range
              </h3>
              <div className="mt-2 px-7 py-3">
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="border p-2 rounded w-full"
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="border p-2 rounded w-full mt-2"
                />
              </div>
              <div className="items-center px-4 py-3">
                <button
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="mt-3 px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Averages Display */}
      <div className="mb-4 grid grid-cols-3 gap-4">
        <div className="bg-gray-200 p-4 text-center rounded">
          RAU: {rau[0]?.rau}
        </div>
        <div className="bg-gray-200 p-4 text-center rounded flex justify-center items-center">
          <span className="text-center mr-2">
            RAU-(Unique): {rauU[0]?.rau_u}
          </span>
          <i
            className="fas fa-envelope cursor-pointer "
            onClick={() => handleEmailClick(rauU[0]?.emails)}
            style={{ flexShrink: 0 }}
          ></i>
        </div>

        <div className="bg-gray-200 p-4 text-center rounded">
          Average DAU-U: {avgDau[0]?.avg_dau_u}
        </div>
      </div>

      {/* Data Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                S.No
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Date
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                DAU
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                DAU-(Unique)
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {data.map((item, index) => (
              <tr key={item.id}>
                <td className="text-left py-3 px-4">{index + 1}</td>
                {new Date(item.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                <td className="text-left py-3 px-4">{item.dau}</td>
                <td
                  className="text-left py-3 px-4 cursor-pointer underline"
                  onClick={() => handleEmailClick(item.emails)}
                >
                  {item.dau_u}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {emailPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              User Emails
            </h3>
            <div style={{ overflowX: "auto" }}>
              {" "}
              {/* Add a wrapper div with overflowX set to auto */}
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      S.No
                    </th>
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {selectedEmails?.map((email, idx) => (
                    <tr key={idx}>
                      <td className="py-3 px-4">{idx + 1}</td>
                      <td className="py-3 px-4">{email?.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button
              onClick={closeEmailPopup}
              className="mt-3 px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalysisTable;
