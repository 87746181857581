import React, { useEffect, useState } from "react";

import "react-tabs/style/react-tabs.css";
import axios from "axios";

const ContactTable = () => {
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [totalData, setTotalData] = useState([]);

  const totalPages = Math.ceil(totalData.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const newData = totalData.slice(
      (newPage - 1) * rowsPerPage,
      newPage * rowsPerPage
    );
    setCurrentData(newData);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setCurrentData(totalData.slice(0, newRowsPerPage));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_feedb_of_contact_us`
      );
      console.log(response.data); // Make sure this is an array
      // Adjust the following line according to the structure of response.data
      const data = Array.isArray(response.data)
        ? response.data
        : Object.values(response.data);
      setTotalData(data);
      setCurrentData(data.slice(0, rowsPerPage));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="mt-4">
        <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[silver]">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sr. no.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Message
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Message Submission Time
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.map((student, index) => (
                <tr key={index}>
                  <td
                    scope="col"
                    className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {index + 1 + (currentPage - 1) * rowsPerPage}
                  </td>
                  <td
                    scope="col"
                    className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {student.email || "N/A"}
                  </td>
                  <td
                    scope="col"
                    className="max-w-[300px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {" "}
                    {student.feedback_text ? student.feedback_text : "N/A"}
                  </td>

                  <td
                    scope="col"
                    className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {student.current_date || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
          <div className="flex">
            <div
              style={{
                display: rowsPerPage === totalData.length ? "none" : "",
              }}
            >
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`mx-1 px-4 py-2 ${
                  currentPage === 1 ? "bg-gray-500" : "bg-blue-500"
                } text-white`}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              <span className="ml-2 mr-2">{currentPage}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`mx-1 px-4 py-2 ${
                  currentPage === totalPages ? "bg-gray-500" : "bg-blue-500"
                } text-white`}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>
            </div>
            <div>
              <select
                id="rowSelector"
                className="mx-8  rounded"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <option value="150">150</option>
                <option value="300">300</option>
                <option value={totalData.length}>All</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTable;
