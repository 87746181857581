import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/User/Dashboard";

const ProtectedAdminRoutes = ({ element }) => {
  const user_type = localStorage.getItem("access_token");

  if (user_type === "2") {
    return element;
  } else {
    if (user_type === "3") {
      return <Navigate to="/student-dashboard" />;
    }
    if (user_type === null) {
      return <Navigate to="/login" />;
    }
    return <Dashboard />;
  }
};
export default ProtectedAdminRoutes;
