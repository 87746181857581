import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AcknowledgeIcon from "../../../../images/finish.png";
import { useNavigate } from "react-router-dom";

const FinishQuestionLimit = ({
  isLimit,
  setIsLimit,
  setStage,
  stage,
  fetchTopics,
  trialData_1,
  trialData_2,
}) => {
  const navigate = useNavigate("");
  if (!isLimit) return null;

  const handleClose = () => {
    if (stage === "showTopics") {
      setIsLimit(false);
    } else {
      setStage("end");
      setIsLimit(false);
      fetchTopics(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "438px",
          height: "290px",
          maxWidth: "438px",
          maxHeight: "390px",
          borderRadius: "12px",
          overflow: "hidden",
        },
      }}
      open={isLimit}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        onClick={handleClose}
        style={{ position: "absolute", top: "20px", right: "25px" }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="text-center ">
        <center>
          {" "}
          <img src={AcknowledgeIcon} alt="Start Trial" />
        </center>

        <h2 className="text-2xl font-bold text-[#1D192B] mt-4">
          You're on a Roll, But...
        </h2>
        <p className="text-base font-medium text-[#49454F] mt-2">
          {trialData_2 === 0
            ? "Your trial period has expired."
            : trialData_1 === 0
            ? "You've used up free questions."
            : ""}{" "}
          Ready for more? Upgrade now to keep the momentum going!
        </p>
        <button
          onClick={() => {
            window.open("/pricing");
          }}
          className="mt-4 text-md font-semibold bg-[#444780] text-white rounded-lg px-6 py-3 text-center shadow-lg hover:bg-[#37366c] transition-colors"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            color: "#FFFFFF",
          }}
        >
          Upgrade now
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default FinishQuestionLimit;
