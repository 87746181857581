import background from "../../../../images/popup.png";
import check from "../../../../images/check_trial.png";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
const PopupModal = ({ isVisible, onClose, setIsAcknowledgement }) => {
  var subj = localStorage.getItem("subject_name");
  var trial_days = localStorage.getItem("trail_days");
  var subject_id = localStorage.getItem("subject_id");
  const [isSubmiting, SetIsSubmiting] = useState(false);
  const [userCountry, setUserCountry] = useState("");

  const features = [
    {
      title: "Exam-Style Quizzes",
      description: "Practice topic-based exam questions and ace your exams",
    },
    {
      title: "Question Limit",
      description: "Solve and learn from up to 20 questions during your trial.",
    },
    {
      title: `${trial_days}-Day Full Access`,
      description: "Enjoy all MockBot features for an entire week.",
    },
    {
      title: "No Payment Info Needed",
      description: "No financial details required",
    },
    {
      title: "AI Marking & Feedback",
      description: "Experience smart AI analysis and feedback for each answer.",
    },
    {
      title: "Targeted Learning",
      description:
        "Focus and improve weak areas, and prep effectively for your exams.",
    },
  ];

  const StartTrial = async () => {
    SetIsSubmiting(true);
    const token = localStorage.getItem("access_token");
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/start_trial`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          subject_id: subject_id,
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          subject_name: subj,
          country_code: userCountry,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log(
        "data in trial:" + JSON.stringify(data?.trial_data[0]?.days_left)
      );
      localStorage.setItem("days", data?.trial_data[0]?.days_left);
      localStorage.setItem("trial_id", data?.trial_data[0]?.trial_period_id);
      setTimeout(() => {
        // isSubmiting.current=false;
        setIsAcknowledgement(true);
        onClose();
      }, 3000);
    } else {
      //  onClose();
      console.error("Failed to fetch subjects");
    }
  };
  const fetchUserCountry = async () => {
    try {
      // Fetch user's IP address
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const userIP = ipResponse.data.ip;

      // Call IP geolocation API
      const geoResponse = await axios.get(
        `https://ipapi.co/${userIP}/country/`
      );
      setUserCountry(geoResponse.data);
    } catch (error) {
      console.error("Error fetching user country:", error);
      setUserCountry("Unknown");
    }
  };
  useEffect(() => {
    fetchUserCountry();
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-40 backdrop-blur-md">
      <div
        className="relative w-full max-w-lg mx-auto bg-white rounded-lg shadow-xl"
        style={{ maxWidth: "1024px" }}
      >
        <div className="flex flex-col">
          <div
            className="relative bg-cover bg-center bg-no-repeat rounded-t-lg h-40 p-4 flex items-end justify-center"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="text-center gap-2">
              <h3
                className="font-bold "
                style={{
                  fontFamily: "Roboto",
                  fontSize: "28px",
                  lineHeight: "36px",
                  color: "#FFFFFF",
                }}
              >
                Start Your MockBot Trial for {subj}
              </h3>
              <p
                className="font-medium mb-[34px] mt-[8px]"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  letterSpacing: "0.15px",
                }}
              >
                No credit card required!
              </p>
            </div>
          </div>

          <div className="p-6">
            <div className="flex flex-wrap justify-between mb-6">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className="flex items-start w-full md:w-1/3 mb-4 md:mb-4 px-2 "
                >
                  <img
                    src={check}
                    alt="Check"
                    className="flex-none w-6 h-6 mr-2"
                  />
                  <div>
                    <h4
                      className="font-bold "
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#322E33",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {feature.title}
                    </h4>
                    <p
                      className="font-regular"
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#464646",
                        letterSpacing: "0.1px",
                      }}
                    >
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <button
                onClick={StartTrial}
                disabled={isSubmiting === true}
                className="mt-4  text-lg font-semibold bg-[#444780] text-white rounded-lg px-6 py-3 text-center shadow-lg hover:bg-[#37366c] transition-colors"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#FFFFFF",
                  letterSpacing: "0.1px",
                  maxWidth: "272px",
                }}
              >
                Try Free for{" "}
                {trial_days === "1" ? "1 Day" : trial_days + " Days"}
                {isSubmiting === true && (
                  <svg
                    className="animate-spin inline -mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div className="absolute top-0 right-0 p-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-200 transition-colors"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
