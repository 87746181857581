import React, { useState, useEffect } from "react";
import axios from "axios";

const PromoCode = () => {
  const [promoData, setPromoData] = useState([]);
  const [promoMsg, setPromoMsg] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isToggled, setIsToggled] = useState(false); // false represents off (0), true represents on (1)
  const fetchPromoData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_promo_data_admin`
      );
      console.log("data", response.data);
      setPromoData(response.data.promo_data);
    } catch (error) {
      console.error("Error fetching promo data:", error);
    }
  };
  const addPromoCode = async () => {
    try {
      const newPromoData = {
        promo_msg: promoMsg,
        promo_code: promoCode,
        promo_availability: isToggled ? 1 : 0,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/add_promo_code`,
        newPromoData
      );
      console.log("Promo code added:", response.data.msg);
      await fetchPromoData();
      setIsModalOpen(false); // Close modal after adding
    } catch (error) {
      console.error("Error adding promo code:", error);
    }
  };

  const updatePromoCode = async (
    promoCodeId,
    newPromoMsg,
    newPromoCode,
    isToggled
  ) => {
    try {
      const updatedPromoData = {
        promo_code_id: promoCodeId,
        promo_msg: newPromoMsg,
        promo_code: newPromoCode,
        promo_availability: isToggled ? 1 : 0,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_promo_code`,
        updatedPromoData
      );
      console.log("Promo code updated:", response.data.msg);
      await fetchPromoData();
      setIsModalOpen(false); // Close modal after updating
    } catch (error) {
      console.error("Error updating promo code:", error);
    }
  };

  const handleAddOrUpdate = () => {
    if (editMode) {
      updatePromoCode(currentId, promoMsg, promoCode, isToggled);
    } else {
      addPromoCode();
    }
  };

  const openModal = (promo = null) => {
    setIsModalOpen(true);
    if (promo) {
      setEditMode(true);
      setCurrentId(promo.promo_code_id);
      setPromoMsg(promo.promo_msg);
      setPromoCode(promo.promo_code);
      if (promo.promo_availability === 1) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    } else {
      setEditMode(false);
      setPromoMsg("");
      setPromoCode("");
      setIsToggled(false);
    }
  };

  const handleToggle = () => {
    setIsToggled(!isToggled); // toggle the state
  };
  useEffect(() => {
    fetchPromoData();
  }, []);
  return (
    <div className="flex items-center justify-center  bg-gray-100">
      <div className="p-4 bg-white shadow-md rounded-lg w-full max-w-4xl">
        <button
          style={{ display: promoData?.length > 0 ? "none" : "" }}
          onClick={() => openModal()}
          className="mb-4 px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none"
        >
          Create Promo Code
        </button>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full bg-gray-200 text-left">
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  S.No
                </th>
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  Message
                </th>
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  Code
                </th>
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  Availability
                </th>
                <th className="py-3 px-4 uppercase font-semibold text-sm">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {promoData.map((promo, idx) => (
                <tr key={promo.promo_code_id}>
                  <td className="py-3 px-4">{idx + 1}</td>
                  <td className="py-3 px-4">{promo.promo_msg}</td>
                  <td className="py-3 px-4">{promo.promo_code}</td>
                  <td className="py-3 px-4">{promo.promo_availability}</td>
                  <td className="py-3 px-4">
                    <button
                      onClick={() => openModal(promo)}
                      className="mr-2 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
                    >
                      Edit
                    </button>
                    {/* Additional functionality like delete can be implemented here */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {editMode ? "Edit Promo Code" : "Add Promo Code"}
              </h3>
              <div className="mt-4">
                Message:
                <input
                  type="text"
                  placeholder="Promo Message"
                  value={promoMsg}
                  onChange={(e) => setPromoMsg(e.target.value)}
                  className="mb-2 px-3 py-2 border rounded-md w-full"
                />
                Code:
                <input
                  type="text"
                  placeholder="Promo Code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  className="mb-2 px-3 py-2 border rounded-md w-full"
                />
                <div className="mb-2 flex items-center gap-2 mb-2">
                  Availability
                  <label className="relative inline-block w-14 h-8">
                    <input
                      type="checkbox"
                      className="opacity-0 w-0 h-0"
                      checked={isToggled}
                      onChange={handleToggle}
                    />
                    <span
                      className={`absolute cursor-pointer inset-0  rounded-full transition duration-300 ease-in-out ${
                        isToggled ? "bg-blue-500" : "bg-gray-300"
                      }`}
                    >
                      <span
                        className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${
                          isToggled ? "translate-x-6" : "translate-x-0"
                        }`}
                      ></span>
                    </span>
                  </label>
                  {/* Displaying the value based on the toggle state */}
                </div>
                <button
                  onClick={handleAddOrUpdate}
                  className="px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none"
                >
                  {editMode ? "Update Promo Code" : "Add Promo Code"}
                </button>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-3 px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PromoCode;
