import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import success from "../../../../images/payment_done.png";
import back from "../../../../images/arrow_back_stripe.png";
import organizatin from "../../../../images/oraganization.png";
const Confirm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmiting] = useState(false);
  const confirmed = async () => {
    const amount2 = parseInt(localStorage.getItem("amount"));

    const currentDateTime = moment();

    let durationToAdd;
    if (amount2 === 48) {
      // Add one month for amount 48
      durationToAdd = 12;
    } else if (amount2 === 15) {
      // Add one year for amount 15
      durationToAdd = 3;
    } else if (amount2 === 8) {
      // Add three months for amount 8
      durationToAdd = 1;
    } else {
      // Default: Add one month for other amounts
      durationToAdd = 1;
    }

    // Add the determined duration to the current date and time
    const oneMonthLater = currentDateTime.add(durationToAdd, "months");

    const formattedDateTime = oneMonthLater.format("Y-MM-DD HH:mm:ss");

    // Set the state with the formatted date and time

    // Payment was successful, call your additional API with access token
    const token = localStorage.getItem("access_token"); // Retrieve the access token
    const mail = localStorage.getItem("email_address");
    const trial_id = localStorage.getItem("trial_id");
    const amount = localStorage.getItem("amount");
    const subject_id = localStorage.getItem("subject_id");
    const subject_name = localStorage.getItem("subject_name");
    if (trial_id === "upgrade") {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/start_subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
          },
          body: JSON.stringify({
            current_date: moment().format("Y-MM-DD HH:mm:ss"),
            end_date: formattedDateTime,
            subject_id: subject_id,
            subject_name: subject_name,
            amount_paid: amount,
            email: mail,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to call the additional API");
      }

      // Additional API call was successful
      // Perform any necessary actions here
      // setIsSubmiting(false);
      console.log("Additional API call successful");
      // navigate("/");
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/submit_subscriptions_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
          },
          body: JSON.stringify({
            subscription_start: moment().format("Y-MM-DD HH:mm:ss"),
            subscription_end: formattedDateTime,
            trial_period_id: trial_id,
            amount_paid: amount,
            email: mail,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to call the additional API");
      }

      // Additional API call was successful
      // Perform any necessary actions here
      console.log("Additional API call successful");
    }
  };
  const handleBack = () => {
    setIsSubmiting(true);
    setTimeout(() => {
      setIsSubmiting(false);
      navigate("/");
    }, 3000);
  };
  useEffect(() => {
    confirmed();
  }, []);
  let subjectName = localStorage.getItem("subject_name");
  let price = localStorage.getItem("amount");
  return (
    <div className="flex flex-col md:flex-row bg-gray-50">
      {/* Left side */}
      <div className="md:w-1/2 h-screen md:pt-[120px] bg-[#282C4D] text-white py-8 px-4 md:px-[102px] flex flex-col items-center justify-center md:justify-start">
        {/* Back navigation arrow */}
        {/* Company name */}
        <div className="text-center md:text-left">
          <h1 className="text-2xl mb-[37px] font-semibold flex items-center ">
            <button
              onClick={() => navigate("/")}
              className="mr-4 text-gray-400"
            >
              {/* Proper left arrow */}
              <img src={back} className="h-[16px] w-[16px]" />
            </button>
            <img src={organizatin} className="h-[32px] w-[32px] mr-2" />
            Mockbot
          </h1>
          {/* Subscribe to subject name */}
          <p className="text-lg font-semibold text-gray-400">
            Subscribe to {subjectName}{" "}
          </p>
          {/* Yearly subscription */}
          <div className="rounded-lg flex text-black">
            <span
              className="text-[48px] font-bold text-white"
              style={{ lineHeight: "56px", fontFamily: "Roboto" }}
            >
              £{price}
            </span>
            <div className="flex flex-col items-end mt-[10px] ml-1">
              <span
                className="text-[14px] font-semibold text-gray-400 "
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                every
              </span>
              <span
                className="text-[14px] text-gray-400"
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                {price === "48"
                  ? "Year"
                  : price === "15"
                  ? "3 Months"
                  : "Monthly"}
              </span>
            </div>
          </div>
          {/* Paragraph related to subscription */}
          <p className="text-gray-400">
            Practice up to 500 exam questions each month & get instant,
            AI-powered feedback for smarter studying & better grades.
          </p>
        </div>
      </div>

      {/* Right side */}
      <div className="md:w-1/2 bg-white py-8 px-4 md:p-[102px] flex flex-col items-center gap-2 justify-center">
        {/* Subscription details */}
        <img src={success} alt="Success" />
        <h1
          className="font-semibold text-center"
          style={{
            fontFamily: "Roboto",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#322E33",
          }}
        >
          Your upgrade was successful
        </h1>{" "}
        <p className="text-gray-700 mb-4 text-center">
          Thank you for choosing MockBot! Embark on your journey to mastery of
          this subject with our AI-enhanced personalised exam practice
        </p>
        {/* Back to Home button */}
        <button
          onClick={handleBack}
          className="bg-[#444780] text-white font-semibold py-3 px-8 rounded-lg inline-flex items-center justify-center"
          disabled={isSubmitting}
        >
          <span style={{ fontFamily: "Roboto" }} className="inline">
            Back to Home
          </span>
          {isSubmitting && (
            <svg
              className="animate-spin ml-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Confirm;
