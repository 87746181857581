import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import thumbup from "../../../../images/thumb_up.png"
import thumbdown from "../../../../images/thumb_down.png"
import DetailsModal from '../widgets/DetailsModal';
const Feedback = (  Feedback_app,
    handleFeedbackPageChange2,
    currentQuestionFeedbackPage2,
    totalFeedbackPages2,
    numberOfRowsQuestionFeedback2,
    setCurrentQuestionFeedbackPage2,feedbackData,
    handleRowChangeFeedback2) => {
    const [currentData, setCurrentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(150);
  

    const [totalData, setTotalData] = useState([]);
    // const totalPages = Math.ceil(feedbackData?.length / rowsPerPage);
    const totalPages = Math.ceil(totalData.length / rowsPerPage);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalDetails, setModalDetails] = useState({});
    function removeHTMLTags(htmlString) {
        // Create a new DOMParser instance
        var parser = new DOMParser();
        // Parse the HTML string into a document
        var doc = parser.parseFromString(htmlString, 'text/html');
        // Return the text content of the parsed document
        return doc.body.textContent || "";
    }
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      
        const newData = feedbackData?.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
        setCurrentData(newData);
      };
  
      const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1); // Reset to first page
        // setCurrentData(feedbackData?.slice(0, newRowsPerPage));
      };
  
      const handleSeeMoreClick = (item) => {
        setModalDetails({
          email: item.email,
          date: new Date(item.creation_timestamp).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          time: new Date(item.creation_timestamp).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: process.env.REACT_APP_TIME_ZONE === "PAK" ?  'Etc/GMT-5' : 'Etc/GMT',
            hour12: true,
          }),
          subject: item.subject,
          topic: item.topic,
          question: removeHTMLTags(item.question),
          studentAnswer: item.student_answer,
          marksObtained: item.obtained_marks,
          mockbotFeedback: item.ai_feedback,
          actualAnswer: item.actual_answer,
          rubric: item.rubric,
          studentFeedback: item.student_question_feedback,
        });
        setIsModalOpen(true);
      };
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_REST_API_BASE_URL}/get_question_level_feedbacks_authentic_student`);
        console.log(response.data); // Make sure this is an array
    // Adjust the following line according to the structure of response.data
    const data = Array.isArray(response.data.data) ? response.data.data : Object.values(response.data.data);
    setTotalData(data);
    setCurrentData(data.slice(0, rowsPerPage));
    console.log("data here is"+ data)
  } catch (error) {
    console.error('Error fetching data: ', error);
  }

    };
  
  useEffect(()=>{fetchData();},[])
function aggregateWords(input) {
    if (!input || input.toLowerCase() === 'undefined') return 'All Topics'; // Handle null, undefined, or "undefined" string
  
    const words = input.split(',').map(word => word.trim()); // Split and trim spaces
    const counts = {}; // Object to hold word counts
  
    // Iterate over words to populate counts object
    words.forEach(word => {
      // Treat "undefined" as "All Topics"
      const normalizedWord = word.toLowerCase() === 'undefined' ? 'All Topics' : word;
  
      // Increment count for the word, initializing if necessary
      if (counts[normalizedWord]) {
        counts[normalizedWord]++;
      } else {
        counts[normalizedWord] = 1;
      }
    });
  
    // Construct the result string from the counts object
    const result = Object.entries(counts).map(([word, count]) => {
      return count > 1 ? `${word} (${count})` : word;
    });
  
    return result.join(', ');
  }
  

  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Feedback (App Level)</Tab>
          <Tab>Feedback (Question Level)</Tab>
        </TabList>

        <TabPanel>
        <div className="mt-4">
          <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto w-[1300px]">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-[silver]">
             
                  <tr>
                    
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      First Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Retention (unique days)
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Subject
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Topics
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No. of question attempts
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Feedback
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Startover
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                    </th>
                    <th scope="col" className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Latest  Date
                    </th>
                  </tr>
                  
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
             {Feedback_app.Feedback_app.length > 0 ? Feedback_app.Feedback_app.map((feedback, index) => (<tr key={index}>  <td
     
          className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider overflow-hidden relative"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div className="truncate cursor-pointer">
            {feedback.student_email ? feedback.student_email : "N/A"}
            {hoveredIndex === index && (
              <div className="absolute top-2 left-0 w-auto p-2 bg-white shadow-lg border rounded-md z-10 font-[8px]">
                {feedback.student_email}
              </div>
            )}
          </div>
        </td>

        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {feedback.name ? feedback.name.split(' ')[0]  : 'N/A'} {/* First Name */}
        </td>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {feedback.name ? feedback.name.split(' ').slice(1).join(' ').replace("|"," ") : 'N/A'} {/* Last Name */}
        </td>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {feedback.retention ? feedback.retention  : 'N/A'} {/* Last Name */}
        </td>
                    <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {feedback.subject_names ? aggregateWords(feedback.subject_names) : 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {feedback.topic_names ? aggregateWords(feedback.topic_names) : 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[20px] overflow-x-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {feedback.no_of_questions_per_response ? feedback.no_of_questions_per_response : 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[100px] px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {feedback.feedback ? feedback.feedback.split(',').join(',\n') : 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[20px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {feedback.no_of_responses ? feedback.no_of_responses : 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[140px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                     {feedback.creation_dates ?  feedback.creation_dates.split(',').join(',\n'): 'N/A'}
                    </td>
                    <td scope="col" className="max-w-[140px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                     {feedback.latest_date}
                    </td>
                    </tr>)):
                 (<tr><td colSpan={7} className=" px-6 py-3 text-center text-xs font-medium text-gray-900 uppercase tracking-wider">no data in table</td></tr>)}
                </tbody>

              </table>
            </div>

            {/* Pagination */}
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className='flex'>
              <div  className="flex" style={{display:numberOfRowsQuestionFeedback2 ===feedbackData?.length ? 'none' : ''  }}>
            <button
              onClick={() => handleFeedbackPageChange2(currentQuestionFeedbackPage2 - 1)}
              className={`mx-1 px-4 py-2 ${currentQuestionFeedbackPage2 === 1 ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
              disabled={currentQuestionFeedbackPage2 === 1}
            >
              {"<"}
            </button>
            <div> <span className="ml-2 mr-2 flex item-center mt-2">{currentQuestionFeedbackPage2}</span></div>
           
            <button
              onClick={() => handleFeedbackPageChange2(currentQuestionFeedbackPage2 + 1)}
              className={`mx-1 px-4 py-2 ${currentQuestionFeedbackPage2 === setCurrentQuestionFeedbackPage2 ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
              disabled={currentQuestionFeedbackPage2 === setCurrentQuestionFeedbackPage2}
            >
              {">"}
            </button>
            </div>
            <select
           id="rowSelector"  className="mx-8  rounded"
              value={numberOfRowsQuestionFeedback2}
              onChange={handleRowChangeFeedback2}
            >
              <option value="150">150</option>
              <option value="300">300</option>
              <option value={feedbackData?.length}>All</option>
            </select>
          </div>
              </div>
            </div>
          
        </TabPanel>
        <TabPanel>
          {/* User Profile Table */}
          <div className="mt-4">
          <div className="overflow-x-auto shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto w-[1300px]">
              <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-[silver]">
                
                  <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S.NO</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Topic</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Question</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Marks</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Thumbs Up/ Thumbs down</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student Feedback</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View  Details</th>
            </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
  {currentData.map((student, index) => (
    <tr key={index}>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{index + 1 + ((currentPage - 1) * rowsPerPage)}</td>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{student.email || 'N/A'}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          { new Date(student.creation_timestamp).toLocaleDateString('en-US', { year: 'numeric',month: '2-digit',day: '2-digit', timeZone: process.env.REACT_APP_TIME_ZONE === "PAK" ?  'Etc/GMT-5' : 'Etc/GMT',})}
                        </td>
          
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
  {new Date(student.creation_timestamp).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: process.env.REACT_APP_TIME_ZONE === "PAK" ?  'Etc/GMT-5' : 'Etc/GMT',
    hour12: true // Use 24-hour format
  })}
</td>
       
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{student.subject ? student.subject: 'N/A'}</td>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{student.topic ? student.topic: 'N/A'}</td>
        <td className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
  {student.question ? student.question.replace(/<\/?[^>]+(>|$)/g, "") : 'N/A'}
</td>



        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{student.question_marks ? (student.obtained_marks+"/"+student.question_marks)   : 'N/A'}</td>
        <td className="max-w-[100px] px-4 py-3 text-left text-xs font-medium text-gray-500 ">{student.student_response === 1 ? <img src={thumbup} alt=""/> : <img src={thumbdown} alt=""/> }</td>
        <td scope="col" className="max-w-[300px] px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{student.student_question_feedback || 'N/A'}</td>
        <td className="max-w-[100px] px-4 py-3 text-left text-xs font-medium text-gray-500">
                <button
              onClick={() => handleSeeMoreClick(student)}
              className="text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
            >
              See more
            </button>
</td>

    </tr>
  ))}
</tbody>

              </table>
            </div>

            {/* Pagination */}
            <div className="w-full flex flex-col items-center justify-center mt-12 mb-12">
              <div className='flex'>
                <div style={{display: rowsPerPage === totalData?.length ? 'none' : '' }}>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`mx-1 px-4 py-2 ${currentPage === 1 ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                  <span className='ml-2 mr-2'>{currentPage}</span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`mx-1 px-4 py-2 ${currentPage === totalPages ? 'bg-gray-500' : 'bg-blue-500'} text-white`}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </div>
                <div> 
                  <select id="rowSelector"  className="mx-8  rounded" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                    <option value="150">150</option>
                    <option value="300">300</option>
                    <option value={totalData?.length}>All</option>
                  </select>
                </div>
              </div>
            </div>
            <DetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} details={modalDetails} />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Feedback
