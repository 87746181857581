import { useContext } from "react";
import MockBotIcon from "../../images/logo-blue.svg";
import Background from "../../images/background.png";
import appContext from "../../context/appContext";

function LoginPageLeftSide(props) {
  const state = useContext(appContext);

  function openInNewTab(url) {
    window.open(url, "_blank").focus();
  }

  return (
    <div
      className="flex flex-col justify-between h-screen w-full bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="flex justify-start p-5">
        <img src={MockBotIcon} alt="MockBot Icon" className="w-[205px]" />
      </div>

      <div className="px-20 ">
        <div
          style={{
            display: window.location.pathname === "/register" ? "" : "none",
          }}
        >
          <h1 className="text-4xl font-bold text-white leading-tight mb-6">
            Smash Your Exams with MockBot's AI Marking & Feedback
          </h1>
          <p className="text-white text-lg mb-6">
            Dive into{" "}
            <span className="border-b border-white">
              topic-based exam questions
            </span>{" "}
            & get{" "}
            <span className="border-b border-white">
              instant, AI-powered feedback
            </span>{" "}
            for smarter studying & better grades. With MockBot, It's not just
            practice; it's targeted improvement.
          </p>
        </div>
        <div
          style={{
            display: window.location.pathname === "/login" ? "" : "none",
          }}
        >
          <h1 className="text-4xl font-bold text-white leading-tight mb-4">
            Welcome to MockBot!
          </h1>
          <p className="text-white text-lg ml-2 mb-[20px]">
            Dive into your exam preparation with Mockbot, your ultimate tool for
            mastering past exam questions. Sign in now to access:
          </p>
          <ul
            className="text-white text-lg list-disc pl-5 mb-6"
            style={{ lineHeight: "32px" }}
          >
            <li>
              <b>Extensive Exam Question Database:</b> Practice with a wide
              range of past exam questions tailored to your syllabus.
            </li>
            <li>
              <b> Instant AI Feedback & Marking:</b> Get detailed, immediate AI
              feedback & marking on your answers.
            </li>
            <li>
              <b>Track Your Progress:</b> See how you're improving and focus on
              areas that need attention.
            </li>
          </ul>
        </div>
        <p className="text-sm text-white mb-6">
          ⚠️ MockBot is in Beta; quiz evaluations may occasionally be
          inaccurate.
        </p>
      </div>

      <div className="flex items-center justify-center text-white text-xs pb-4">
        <div>© MockBot.co All rights reserved</div>
        <div className="ml-4">
          <span
            className="underline cursor-pointer"
            onClick={() => openInNewTab("https://mockbot.co/about")}
          >
            About
          </span>
          <span
            className="underline cursor-pointer ml-4"
            onClick={() => openInNewTab("https://mockbot.co/privacy-policy")}
          >
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  );
}

export default LoginPageLeftSide;
