import React from 'react';

const ProgressBar = ({ accountStatus, daysLeft, trialStart, trialEnd, subscriptionStart, subscriptionEnd }) => {
    const calculatePercentage = () => {
        let totalDays, daysElapsed, daysRemaining;
        const today = new Date();
    
        switch (accountStatus) {
          case 1: // Trial Period
            totalDays = (new Date(trialEnd) - new Date(trialStart)) / (1000 * 60 * 60 * 24);
            daysElapsed = (today - new Date(trialStart)) / (1000 * 60 * 60 * 24);
            daysRemaining = (new Date(trialEnd) - today) / (1000 * 60 * 60 * 24);
            return daysRemaining >= 0 ? (daysLeft / totalDays) * 100 : 0;
          case 3: // Subscription Period
            totalDays = (new Date(subscriptionEnd) - new Date(subscriptionStart)) / (1000 * 60 * 60 * 24);
            daysElapsed = (today - new Date(subscriptionStart)) / (1000 * 60 * 60 * 24);
            daysRemaining = (new Date(subscriptionEnd) - today) / (1000 * 60 * 60 * 24);
            return daysRemaining >= 0 ? (daysLeft / totalDays) * 100 : 0;
          case 2: // Account status 2, fixed percentage
            return 10;
          default:
            return 0; // Handle unknown status by showing 0%
        }
      };
    
      const getColor = () => {
        const percentage = calculatePercentage();
        if (accountStatus === 1 || accountStatus === 3) {
          return percentage >= 100 ? 'bg-green-500' : 'bg-purple-500';
        }
        return 'bg-red-500'; // Default color for account status 2
      };
    
    
  return (
    <div className="w-[200px] bg-gray-200 h-[8px] my-2">
     <div className={`h-full ${getColor()}`} style={{ width: `${Math.min(calculatePercentage(), 100)}%` }}></div>

    </div>
  );
};

export default ProgressBar;
