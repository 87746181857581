import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { useNavigate } from "react-router-dom";
import icon1 from "../../../images/Icons.png";
import icon2 from "../../../images/Icons_2.png";
import icon3 from "../../../images/icons_3.png";
import icon4 from "../../../images/icons_4.png";
import right_arrow from "../../../images/arrow_right_alt.png";
import cut from "../../../images/content_cut.png";
import moment from "moment";
import PopupModal from "./widgets/PopUpModal";
import TrialPeriodAcknowledgmentPopup from "./widgets/TrialPeriodAcknowledgmentPopup";
import FinishQuestionLimit from "./widgets/FinishQuestionLimit";
import ProgressBar from "./widgets/ProgressBar ";
import LoadingIndicator from "./widgets/LoadingIndicator";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import axios from "axios";
const Dashboard = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [name, setName] = useState("");
  const [data, setData] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isAcknowledgement, setIsAcknowledgement] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promoCode, setPromoCode] = useState();
  const [show, setShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const handleCopyToClipboard = () => {
    if (promoCode?.length > 0 && promoCode[0]?.promo_code) {
      navigator.clipboard
        .writeText(promoCode[0].promo_code)
        .then(() => {
          // Success: Show confirmation to the user
          setShow(true);
          console.log("Promo code copied successfully!");
        })
        .catch((err) => {
          // Error: Could not copy text
          console.error("Could not copy promo code", err);
          // Optionally, handle the error by showing a message to the user
        });
    } else {
      // Handle the case where promo code is not available
      console.error("No promo code available to copy");
      // Optionally, inform the user that there's no promo code
    }
  };

  const getProfileData = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    const mail = localStorage.getItem("email_address");
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/get_profile_data`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          email: mail,
        }),
      }
    );
    if (response.ok) {
      setIsLoading(false);
      const data = await response.json();
      setData(data.data);
      const subjectsFromData = data.data
        .flatMap((item) => (item.subject_list ? item.subject_list : []))
        .sort(
          (a, b) =>
            new Date(b.creation_timestamp) - new Date(a.creation_timestamp)
        );
      setSubjects(subjectsFromData);
      if (data.data.length > 0) {
        setName(data.data[0].name);
      }
      console.log("subjects set:", subjectsFromData);
    } else {
      console.error("Failed to fetch subjects");
    }
  };

  const handleUpgrade = async (id, name, trial_id) => {
    const token = localStorage.getItem("access_token");
    localStorage.setItem("subject_id", id);
    localStorage.setItem("trial_id", trial_id);
    localStorage.setItem("subject_name", name);
    const response = await fetch(
      `${process.env.REACT_APP_REST_API_BASE_URL}/submit_buy_interest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          subject_id: id,
          subject_name: name,
        }),
      }
    );
    if (response.ok) {
      window.open("/pricing");
      // navigate("/pricing");
    } else {
      console.error("Failed");
    }
  };
  const fetchPromoData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_promo_data`
      );
      console.log("data", response.data);
      setPromoCode(response.data.promo_data);
      if (response.data.promo_data.length > 0) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    } catch (error) {
      console.error("Error fetching promo data:", error);
      setIsShow(false);
    }
  };
  useEffect(() => {
    fetchPromoData();
    getProfileData();
  }, []); // Empty dependency array to run only once on component mount
  useEffect(() => {
    if (window.location.pathname === "/login") {
      document.title = "Login | MockBot";
    } else if (window.location.pathname === "/register") {
      document.title = "Sign Up | MockBot";
    } else {
      document.title = "MockBot"; // Optionally reset title when component unmounts
    }
  }, []);
  useEffect(() => {
    if (data?.length === 0) {
      navigate("/about_you");
    }
  }, [data]); // Empty dependency array to run only once on component mount

  const handleClick = (id, name, trialData, days) => {
    if (trialData.length === 0) {
      localStorage.setItem("trail_days", days);
      setIsPopupVisible(true);
      localStorage.setItem("subject_id", id);
      localStorage.setItem("subject_name", name);
    } else {
      localStorage.setItem("subject_id", id);
      localStorage.setItem("subject_name", name);
      localStorage.setItem("trial_id", trialData[0]?.trial_period_id);

      navigate("/quiz");
    }
  };

  const icons = [icon1, icon2, icon3, icon4]; // Array of your icons
  return (
    <>
      <Header />
      <ToastContainer className="fixed top-20 right-4 w-[340px]">
        <Toast
          className="bg-white border border-gray-300 rounded-md shadow-md"
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header
            closeButton={false}
            className="flex items-center justify-between px-2 py-2 border-b border-gray-300"
          >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className=" font-bold  mr-auto ">Copied!</strong>
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </Toast.Header>
          <Toast.Body className="px-2 py-2  bg-[#d8b8ff] opacity-65 ">
            <span className="mr-2"> </span> Promo code has been copied.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {isShow && !isLoading && (
        <div
          className="w-full flex   pb-[8px]  text-dark"
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            backgroundColor: "#FFD015",
          }}
        >
          <p className="text-center w-full mt-2  font-[600]">
            {promoCode?.length > 0 ? promoCode[0]?.promo_msg : ""}
            <span className="mr-2 ml-2 text-[20px]">→</span>
            <button
              onClick={handleCopyToClipboard}
              className="pb-[8px]  pt-[8px] pl-[16px] pr-[16px]  ml-2  rounded-lg  text-white bg-black"
              style={{ fontFamily: "Roboto" }}
            >
              <span className="flex gap-1">
                {" "}
                <img
                  className="h-[16px] w-[16px] mt-[2px]"
                  src={cut}
                  alt=""
                />{" "}
                {promoCode?.length > 0 ? promoCode[0]?.promo_code : ""}
              </span>
            </button>
          </p>
        </div>
      )}
      {isLoading && (
        <LoadingIndicator
          isVisible={isLoading}
          showText={data.length ? "block" : "none"}
        />
      )}
      {!isLoading && (
        <div
          className="container mx-auto  my-8  md:p-5 sm:p-0 bg-white rounded shadow"
          style={{ display: isLoading && "none" }}
        >
          <h1
            className="text-3xl font-bold text-[#322E33]text-left"
            style={{
              visibility: isLoading ? "hidden" : "visible",
              fontFamily: "Roboto",
              fontSize: "28px",
              lineHeight: "36px",
            }}
          >
            Welcome Aboard, {name?.replace("|", " ")}!
          </h1>
          <p
            className="text-left mt-2 text-[#464646]"
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            Please choose a subject you wish to practice with MockBot's AI
            marking & feedback.
          </p>
          <div className="flex justify-between   items-center mt-8  bg-gray-100 rounded-tl-[4px] rounded-tr-[4px]">
            <h2 className="text-lg font-semibold text-gray-700  py-2 px-4 rounded-l-lg mb-[26px] mt-[18px]">
              My Subjects
            </h2>
            <button
              onClick={() => {
                navigate("/about_you", {
                  state: {
                    isProfile: true,
                  },
                });
              }}
              className="text-[#444780] font-bold py-2 px-4 rounded border border-[#444780] hover:bg-purple-100 border-2 transition ease-in-out duration-300 mr-2"
            >
              + Add/edit subjects
            </button>
          </div>
          <div className="bg-[#F2F2F2]  rounded-lg">
            <div className="overflow-x-auto">
              <table className="min-w-full text-left">
                <thead className="bg-[#E0E0E0] ">
                  <tr>
                    <th
                      className="px-4 py-3 border-b-2 border-gray-200 text-gray-600"
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Subject
                    </th>
                    <th
                      className="px-4 py-3 border-b-2 border-gray-200 text-gray-600"
                      style={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Enrolment date
                    </th>
                    <th className="px-4 py-3 border-b-2 border-gray-200"></th>
                    <th className="px-4 py-3 border-b-2 border-gray-200"></th>
                  </tr>
                </thead>
                <tbody>
                  {subjects.map((subject, index) => (
                    <tr key={index} className="bg-[#F2F2F2]">
                      <td
                        className="px-6 py-10 border-b border-gray-200 font-medium text-gray-700 flex items-center"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {/* Insert the icon next to the subject name */}
                        <img
                          src={icons[index % icons.length]}
                          alt="Icon"
                          className="mr-2 h-[32px] w-[32px]"
                        />
                        <button
                          onClick={
                            subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 4
                              ? () => {
                                  handleUpgrade(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data?.[0]?.trial_period_id
                                  );
                                }
                              : () => {
                                  handleClick(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data,
                                    subject.trial_free_days
                                  );
                                }
                          }
                        >
                          {subject.subject_name}
                        </button>
                      </td>
                      <td
                        className="px-4 py-2 border-b border-gray-200 text-gray-500"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {new Date(
                          subject.creation_timestamp
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          timeZone: "Etc/GMT",
                        })}
                      </td>
                      <td
                        className="px-6 py-3 border-b border-gray-200 text-left"
                        onMouseEnter={() => {
                          document.getElementById(
                            `last_row_${index}`
                          ).style.display = "";
                          document.getElementById(
                            `last_row2_${index}`
                          ).style.display = "none";
                        }} // Show corresponding element on mouse enter
                        onMouseLeave={() => {
                          document.getElementById(
                            `last_row_${index}`
                          ).style.display = "none";
                          document.getElementById(
                            `last_row2_${index}`
                          ).style.display = "";
                        }}
                        style={{
                          display: subject.trial_data?.length > 0 ? "" : "none",
                        }}
                      >
                        {/* Trial Status Text */}
                        <div
                          className="pb-1"
                          style={{
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            color: "#464646",
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.5px",
                            display:
                              subject.trial_data?.length > 0 ? " " : "none",
                          }}
                        >
                          {subject.trial_data[0]?.account_status === 1
                            ? "Free trial"
                            : subject.trial_data[0]?.account_status === 2
                            ? "Trial expired"
                            : subject.trial_data[0]?.account_status === 3
                            ? "Subscribed"
                            : "Subscription expired"}
                        </div>
                        {/* Progress Bar */}
                        <ProgressBar
                          style={{ width: "100px" }}
                          accountStatus={subject.trial_data[0]?.account_status}
                          daysLeft={subject.trial_data[0]?.days_left}
                          trialStart={
                            subject.trial_data[0]?.trial_start_timestamp
                          }
                          trialEnd={subject.trial_data[0]?.trial_end_timestamp}
                          subscriptionStart={
                            subject.trial_data[0]?.subscription_start_timestamp
                          }
                          subscriptionEnd={
                            subject.trial_data[0]?.subscription_end_timestamp
                          }
                        />
                        <span
                          className="flex"
                          style={{
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            color: "#464646",
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.5px",
                            visibility:
                              subject.trial_data[0]?.account_status === 2 &&
                              "hidden",
                          }}
                          id={`last_row2_${index}`}
                        >
                          {subject.trial_data?.[0]?.days_left > 1
                            ? subject.trial_data?.[0]?.days_left + " days left"
                            : subject.trial_data?.[0]?.days_left + " day left"}
                        </span>

                        {/* Clickable Upgrade Text */}
                        <span
                          onClick={
                            subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 6 ||
                            subject.trial_data[0]?.account_status === 1
                              ? () => {
                                  handleUpgrade(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data?.[0]?.trial_period_id
                                  );
                                }
                              : () => {}
                          }
                          className={
                            subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 6 ||
                            subject.trial_data[0]?.account_status === 1
                              ? `cursor-pointer  flex `
                              : ` flex pointer-events-none`
                          }
                          id={`last_row_${index}`} // Ensure each element has a unique ID
                          style={{
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            color: "#464646",
                            fontSize: "12px",
                            lineHeight: "16px",
                            letterSpacing: "0.5px",
                            display: "none",
                          }}
                          // Hide corresponding element on mouse leave
                        >
                          {subject.trial_data[0]?.account_status === 2 ||
                          subject.trial_data[0]?.account_status === 6 ||
                          subject.trial_data[0]?.account_status === 1 ? (
                            <>
                              Upgrade{" "}
                              <img
                                height={12}
                                width={12}
                                className="ml-2"
                                src={right_arrow}
                                alt=""
                              />
                            </>
                          ) : (
                            `${
                              subject.trial_data?.[0]?.days_left > 1
                                ? subject.trial_data?.[0]?.days_left +
                                  " days left"
                                : subject.trial_data?.[0]?.days_left +
                                  " day left"
                            }`
                          )}
                        </span>
                      </td>
                      <td
                        className={`px-6 py-3 border-b border-gray-200 text-left ${
                          subject.trial_data.length === 0 && "!pl-[14px]"
                        }`}
                      >
                        <button
                          className={`w-[180px] rounded py-2 px-6 text-sm transition ease-in-out duration-300 ${
                            subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 4
                              ? "bg-[#F8F9FE] text-[#444780]  border border-[#444780] hover:opacity-45 "
                              : "bg-[#444780] text-white hover:opacity-45"
                          }`}
                          onClick={
                            subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 6
                              ? () => {
                                  handleUpgrade(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data?.[0]?.trial_period_id
                                  );
                                }
                              : () => {
                                  handleClick(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data,
                                    subject.trial_free_days
                                  );
                                }
                          }
                          style={{
                            display: subject.trial_data.length === 0 && "none",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                          }}
                        >
                          {subject.trial_data[0]?.account_status === 2 ||
                          subject.trial_data[0]?.account_status === 6
                            ? "⚡ Upgrade now! "
                            : "Start practicing"}
                        </button>
                        <button
                          className={`w-[180px] ml-2 rounded py-2 px-6 text-sm transition ease-in-out duration-300 ${
                            subject.trial_data.length === 0
                              ? "bg-[#F8F9FE] text-[#444780]  border border-[#444780] hover:opacity-45 "
                              : "bg-[#444780] text-white hover:opacity-45"
                          }`}
                          onClick={
                            subject.trial_data.length === 0
                              ? () => {
                                  handleUpgrade(
                                    subject.subject_id,
                                    subject.subject_name,
                                    "upgrade"
                                  );
                                }
                              : () => {
                                  handleClick(
                                    subject.subject_id,
                                    subject.subject_name,
                                    subject.trial_data,
                                    subject.trial_free_days
                                  );
                                }
                          }
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            display:
                              subject.trial_data.length === 0 ? "" : "none",
                          }}
                        >
                          {subject.trial_data.length === 0
                            ? "⚡ Upgrade now!"
                            : "Start practicing"}
                        </button>
                      </td>
                      {subject.trial_data.length === 0 && (
                        <td className="px-6 py-3 border-b border-gray-200 text-left">
                          <button
                            className={`w-[180px] rounded py-2 px-6 text-sm transition ease-in-out duration-300 ${
                              subject.trial_data[0]?.account_status === 2 ||
                              subject.trial_data[0]?.account_status === 4
                                ? "bg-[#F8F9FE] text-[#444780]  border border-[#444780] hover:opacity-45 "
                                : "bg-[#444780] text-white hover:opacity-45"
                            }`}
                            onClick={
                              subject.trial_data[0]?.account_status === 2 ||
                              subject.trial_data[0]?.account_status === 6
                                ? () => {
                                    handleUpgrade(
                                      subject.subject_id,
                                      subject.subject_name,
                                      subject.trial_data?.[0]?.trial_period_id
                                    );
                                  }
                                : () => {
                                    handleClick(
                                      subject.subject_id,
                                      subject.subject_name,
                                      subject.trial_data,
                                      subject.trial_free_days
                                    );
                                  }
                            }
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                            }}
                          >
                            {subject.trial_data[0]?.account_status === 2 ||
                            subject.trial_data[0]?.account_status === 6
                              ? "💪 Upgrade now!"
                              : "Start practicing"}
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <PopupModal
            isVisible={isPopupVisible}
            onClose={() => setIsPopupVisible(false)}
            setIsAcknowledgement={setIsAcknowledgement}
          />
          <TrialPeriodAcknowledgmentPopup
            isAcknowledgement={isAcknowledgement}
            setIsAcknowledgement={setIsAcknowledgement}
          />
          <FinishQuestionLimit isLimit={isLimit} setIsLimit={setIsLimit} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
