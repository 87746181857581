import { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);

  const navigate = useNavigate();
  const fetchConfig = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/config`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const { publishableKey } = await response.json();
        setStripePromise(loadStripe(publishableKey));
      } else {
        console.error("Failed to fetch config");
      }
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  };

  const createPaymentIntent = async () => {
    try {
      const stripe = await stripePromise;
      const token = localStorage.getItem("access_token");
      const amounts = localStorage.getItem("amount");
      const mail = localStorage.getItem("email_address");
      const plan = localStorage.getItem("plan");
      const product_id = localStorage.getItem("product_id");
      const sbjID = localStorage.getItem("subject_id");
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_BASE_URL}/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject_id: sbjID,
            email: mail,
            product_id: product_id,
            plan: plan,
            unit_amount: amounts * 100,
          }),
        }
      );

      if (response.ok) {
        const { sessionId } = await response.json();

        // Set the session ID in the state

        // console.log("session id is" + sessionId);
        // Redirect the user to the Stripe Checkout page
        const result = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (result.error) {
          // Inform the customer that there was an error.
          alert(result.error.message);
        } else {
          console.log("NO ERROR");
        }
      } else {
        console.error("Failed to create payment intent");
      }
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };
  useEffect(() => {
    fetchConfig();
  }, []);
  useEffect(() => {
    if (stripePromise !== null) {
      createPaymentIntent();
    }
  }, [stripePromise]);

  return (
    <div>
      <Header />

      <div className="flex flex-col items-center  mt-[120px] justify-center">
        <svg
          aria-hidden="true"
          role="status"
          className="inline ml-2 w-[6%] h-[15%] me-3 animate-spin"
          viewBox="0 0 100 101"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="#E5E7EB"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="purple"
          />
        </svg>

        <p className="text-lg text-[#464646] mt-8">
          Almost there! Gearing up your route to top scores.
        </p>
      </div>
    </div>
  );
}

export default Payment;
